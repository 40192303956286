import React, { Component } from "react";
import Autocomplete from "react-autocomplete";
import axios from "axios";
import { BASE_URL } from "../config";
import OpportunityIcon from "../images/DealsIntroLogo.svg";
import AccountIcon from "../images/AccountIcon.svg";
import CarrierGreenIcon from "../images/CarrierGreenIcon.svg";
import ReportNavbar from "../images/ReportsLogo.svg";
import ContactIcon from "../images/ContactsIcon.svg";
import { withRouter } from "react-router-dom";

// Redux stuff
import { connect } from "react-redux";
import { setTab, setActiveTabIndex } from "../redux";
import ReactTooltip from "react-tooltip";

class SearchInput extends Component {
  state = {
    value: "",
    searchItems: [],
    windowWidth: window.innerWidth,
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  changeHandler = (e) => {
    const { value } = e.target;
    this.setState({ value: e.target.value }, () =>
      axios({
        method: "GET",
        url: `${BASE_URL}/dashboard/search?query=${encodeURIComponent(value)}`,
        headers: {
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("authToken"))?.access_token,
        },
      }).then((res) => {
        this.setState({ searchItems: res.data.data });
      })
    );
  };
  newTabData = () => {
    this.state.searchItems.map((item) => {
      const { id, name, type } = item;
      return {
        type: type,
        id: id,
        name: name,
      };
    });
  };
  render() {
    return (
      <Autocomplete
        // style={{zIndex:'2147483647',position:'relative'}}
        inputProps={{
          style: {
            marginLeft: "10px",
            paddingLeft: "15px",
            height: "31px",
            border: "none",
            borderRadius: "13px",
            width: this.state.windowWidth > 600 ? "200px" : "140px",
            fontSize: "14px",
            color: "#CFCFD8",
            outline: "none",
            background: "#3B4043",
            transform: "translateY(-10px)",
          },
          placeholder: "Search",
        }}
        // wrapperStyle={{ width:  '100%'   }}
        items={this.state.searchItems}
        menuStyle={{
          borderRadius: "3px",
          left: "70px",
          display: "block",
          boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
          background: "#252a2d",
          padding: "3px 0",
          position: "fixed",
          overflow: "auto",
          maxHeight: "250px",
          zIndex: "2147483647",
        }}
        // shouldItemRender={(item, value) => item.name.toLowerCase().indexOf(value.toLowerCase()) > -1}
        getItemValue={(item) => item.name}
        renderItem={(item, highlighted) => {
          let newTabData = {
            type: item.type,
            id: item.id,
            name: item.name,
          };
          const { type, id } = item;
          let logo = "";
          if (type === "account") {
            logo = (
              <img
                src={AccountIcon}
                alt=""
                width="13"
                key={id + type}
                style={{ transform: "translateY(9px)" }}
              />
            );
          } else if (type === "report") {
            logo = (
              <img
                src={ReportNavbar}
                alt=""
                width="13"
                key={id + type}
                style={{ transform: "translateY(9px)" }}
              />
            );
          } else if (type === "contact") {
            logo = (
              <img
                src={ContactIcon}
                alt=""
                width="13"
                key={id + type}
                style={{ transform: "translateY(9px)" }}
              />
            );
          } else if (type === "carrier") {
            logo = (
              <img
                src={CarrierGreenIcon}
                alt=""
                width="13"
                key={id + type}
                style={{ transform: "translateY(9px)" }}
              />
            );
          } else {
            logo = (
              <img
                src={OpportunityIcon}
                alt=""
                width="13"
                key={id + type}
                style={{ transform: "translateY(9px)" }}
              />
            );
          }
          return (
            <div
              onClick={() => {
                this.props.setActiveTabIndex(this.props.totalTabs + 1);
                this.props.setTab(newTabData);
                this.props.history.push("/active-tabs");
              }}
              key={`${item.id}${item.type}`}
              style={{
                backgroundColor: highlighted ? "#3B4043" : "#252a2d",
                color: "#CFCFD8",
                zIndex: "2147483647",
                fontSize: "16px",
                cursor: "pointer",
                borderBottom: "1px solid black",
                padding: "5px 0",
              }}
              data-tip={item.name}
            >
              <ReactTooltip />
              <div style={{ paddingLeft: "15px" }}>
                {logo}
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    marginLeft: "15px",
                  }}
                >
                  {item.name.length > 23
                    ? item.name.substring(0, 22)
                    : item.name}
                </span>
              </div>
              <div
                style={{
                  marginLeft: "45px",
                  textTransform: "capitalize",
                  fontSize: "14px",
                  fontWeight: "300",
                  color: "#A5A1AC",
                }}
              >
                {item.type === "opportunity" ? "deal" : item.type}
              </div>
            </div>
          );
        }}
        value={this.state.value}
        onChange={this.changeHandler}
        onSelect={(value, item) => {
          this.props.setActiveTabIndex(this.props.totalTabs + 1);
          this.props.setTab({
            type: item.type,
            id: item.id,
            name: item.name,
          });
          this.props.history.push("/active-tabs");
        }}
      />
    );
  }
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
  };
};

export default connect(MSP, MDP)(withRouter(SearchInput));
