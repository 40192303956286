import React, { Component } from "react";
import XLSX from "xlsx";
import axios from "axios";
import { BASE_URL } from "../../config";
import _ from "lodash";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import TaskTourImport from "../Onboarding/TaskTourImport";
import moment from "moment";
import YoutubePlayer from "../YoutubePlayer";
import { accountHeaderMapping } from "./constants/accountHeaderMapping";
import { carrierHeaderMapping } from "./constants/carrierHeaderMapping";
import { contactHeaderMapping } from "./constants/contactHeaderMapping";
import AccountsImportTemplate from "../../documents/AccountsImportTemplate.csv";
import ContactsImportTemplate from "../../documents/ContactsImportTemplate.csv";
import CarriersImportTemplate from "../../documents/CarriersImportTemplate.csv";
// Redux stuff
import { connect } from "react-redux";
import {
  setTab,
  setActiveTabIndex,
  companyOnboardingSet,
  authUserSet,
} from "../../redux";
import RedirectToBillingModal from "./RedirectToBillingModal";

const date = new Date();
const myDate = moment(date).format("l");

class ImportPage extends Component {
  state = {
    format: "",
    data: [],
    fieldsData: [],
    headers: [],
    headersMapping: {},
    rows: [],
    isLoading: false,
    erroredData: [],
    totalCreated: 0,
    totalFailed: 0,
    tourComplete: false,
    showYoutubeVideo: false,
    reportCreated: false,
    reportData: {},
    reportError: false,
    showRedirectModal: false,
    recordsLimit: 50000,
    recordLimitCheck: false,
    importName: ``,
  };
  fileInput = React.createRef();
  importType = window.location.pathname.split("/").at(-1);

  handleCloseRedirectModal = () => {
    this.setState({
      showRedirectModal: false,
    });
  };
  handleOpenRedirectModal = () => {
    this.setState({
      showRedirectModal: true,
    });
  };
  getRecordsLimit = () => {
    axios({
      method: "GET",
      url: `/company/check-records-limit`,
    }).then((res) => {
      this.setState({
        recordsLimit: res.data.records_limit - res.data.total_records_created,
        recordLimitCheck: true,
      });
    });
  };
  componentDidMount() {
    const pathname = `${window.location.pathname.split("/").at(-1)}`;
    const importName = `${
      pathname.charAt(0).toUpperCase() + pathname.slice(1)
    }s imported on ${myDate}`;
    this.setState({
      importName,
    });
    this.getRecordsLimit();
    axios({
      method: "GET",
      url: `${BASE_URL}/fields/${this.importType}`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
    })
      .then((response) => {
        this.setState({
          fieldsData: response.data.fields,
        });
      })
      .catch((error) => console.log("error", error));
  }
  headersChangeHandler = (sheetHeader, e) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      headers: [...prevState.headers, value],
      headersMapping: { ...prevState.headersMapping, [sheetHeader]: value },
    }));
  };
  showRows = () => {
    this.setState((prevState) => ({
      rows: this.state.data.filter((d) => Object.keys(this.state.headers)),
    }));
  };

  OnDrop = (file, e) => {
    this.setState({ tourComplete: true });
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? "binary" : "array",
        bookVBA: true,
      });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws, {
        header: 0,
        defval: null,
      });
      if (data) {
        console.log("json import", data);
        if (data.length > this.state.recordsLimit) {
          this.handleOpenRedirectModal();
          return;
        }
        if (data.length > 25000) {
          NotificationManager.error(
            "Please upload a csv file with less than 25,000 entries"
          );
          return;
        }
        this.setState({
          data,
        });
      }
    };
    reader.readAsBinaryString(file.files[0]);
  };

  submitAttachment = (e) => {
    e.preventDefault();
    if (!Object.values(this.state.headersMapping).includes("name")) {
      NotificationManager.error("Account Name field is mandatory");
      return false;
    }
    this.setState({ isLoading: true });
    const sheetHeaders = Object.values(this.state.headersMapping)?.filter(
      (key) => key !== ""
    );
    const sheetData = this.state.data.map((obj) =>
      Object.keys(this.state.headersMapping)
        .filter((key) => this.state.headersMapping[key] !== "")
        .map((key) => obj[key])
        .map((cell) => {
          if (typeof cell === "string") {
            return cell.replace(/\r?\n|\r/g, " "); // Replace newline characters with spaces
          }
          return cell; // Leave non-string values unchanged
        })
    );
    const fullSheet = [sheetHeaders, ...sheetData];
    console.log("fullSheet", fullSheet);
    let dataInExcel = XLSX.utils.aoa_to_sheet(fullSheet);
    let dataInCSV = XLSX.utils.sheet_to_csv(dataInExcel, { FS: "," });
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, dataInExcel, "Test");
    console.log("errorDataInCSV:", dataInCSV);
    const filename = "import.csv";
    const formData = new FormData();
    const blob = new Blob([dataInCSV], { type: "text/csv;charset=utf-8" });
    blob.name = filename;
    formData.append("attachment", blob, filename);
    axios({
      method: "POST",
      url: `/attachments/file-upload`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    })
      .then((res) => {
        console.log("document uploaded", res);
        this.importData(res.data.s3_url);
      })
      .catch((err) => {
        const error = err?.response?.data.message || "Error importing";
        NotificationManager.error(error);
        this.setState({ isLoading: false });
      });
  };
  importData = (file_url) => {
    axios({
      method: "POST",
      url: `/import/records`,
      data: {
        name: this.state.importName,
        file_url,
        record_type: `${this.importType}s`,
      },
    })
      .then((res) => {
        this.setState({ isLoading: false });
        console.log("import response", res);
        NotificationManager.success(res.data?.message);
        this.props.history.push("/import");
      })
      .catch((err) => {
        const error = err?.response?.data.message || "Error importing";
        NotificationManager.error(error);
        this.setState({ isLoading: false });
      });
  };
  handleDownload = () => {
    // The relative path to the CSV file in the public directory
    const filePath =
      this.importType === "account"
        ? AccountsImportTemplate
        : this.importType === "carrier"
        ? CarriersImportTemplate
        : ContactsImportTemplate;

    // Create a temporary link element
    const link = document.createElement("a");
    link.href = filePath;
    link.download = `${this.importType}ImportTemplate.csv`; // The name for the downloaded file
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  render() {
    let dynamicFields = this.state.fieldsData
      .map((data) => data.fields)
      .flat(1)
      .reduce((obj, item) => ((obj[item.name] = item.label), obj), {});
    const headerMapping =
      this.importType === "account"
        ? accountHeaderMapping
        : this.importType === "carrier"
        ? carrierHeaderMapping
        : contactHeaderMapping;

    let headerKeyValueMappings = {
      ...headerMapping,
      ...dynamicFields,
    };
    console.log(
      "headerMapping",
      this.state.headersMapping,
      "const",
      headerMapping,
      "object",
      Object.entries(headerMapping)
    );
    return (
      <div style={{ margin: "auto 30px" }}>
        {this.state.showYoutubeVideo && (
          <YoutubePlayer
            src="https://www.youtube.com/embed/dUY5d0OzQSw"
            closeIframe={() => this.setState({ showYoutubeVideo: false })}
          />
        )}
        <NotificationContainer />
        <RedirectToBillingModal
          showOpportunityModal={this.state.showRedirectModal}
          setShowOpportunityModal={this.handleOpenRedirectModal}
          handleCloseModal={this.handleCloseRedirectModal}
          userRole={this.props.authUser.role}
        />
        <form onSubmit={this.submitAttachment}>
          <div
            style={{
              borderBottom: "1px solid #E3E3E3",
              marginBottom: "10px",
            }}
          >
            <div style={{ display: "inline-block" }}>
              <h2 style={{ marginBottom: "0px" }}>
                Import {this.importType}s
                <button
                  type="button"
                  onClick={() => this.setState({ showYoutubeVideo: true })}
                  className="button-no-border-red"
                  style={{ color: "#61c99d", marginLeft: "20px" }}
                >
                  <i className="fa fa-video-camera" aria-hidden="true"></i>
                  &nbsp; Walk-through video
                </button>
                <button onClick={this.handleDownload} className="button-sm">
                  Download Template
                </button>
              </h2>
              <p>
                Please upload a .csv or .xlsx file to import and map the fields
                for the data
              </p>
            </div>
            {this.fileInput?.current?.files[0] && (
              <div
                style={{
                  marginTop: "4vh",
                  float: "right",
                  marginRight: "30px",
                }}
              >
                <button
                  type="submit"
                  className="button-md"
                  disabled={this.state.isLoading}
                >
                  {this.state.isLoading ? "Please Wait..." : "Start Upload"}
                </button>
              </div>
            )}
          </div>
          <div style={{ display: "grid", gridTemplateColumns: "30% 70%" }}>
            <div>
              <label
                htmlFor="file-upload"
                // className="tour-import-account"
                style={{
                  textDecoration: "none",
                  background: !this.state.recordLimitCheck ? "#eee" : "#FFFFFF",
                  color: "#3AAB7B",
                  padding: "10px 24px",
                  borderRadius: "4px",
                  border: "1px solid #3AAB7B",
                  cursor: "pointer",
                  display: "inline-block",
                  marginRight: "10px",
                  fontWeight: "500",
                  fontSize: "12px",
                  lineHeight: "150%",
                }}
              >
                {!this.state.recordLimitCheck
                  ? "Checking Records Limit"
                  : this.fileInput?.current?.files[0]?.name
                  ? "File Name"
                  : "Choose File"}
              </label>
              <input
                style={{ display: "none" }}
                disabled={!this.state.recordLimitCheck}
                id="file-upload"
                type="file"
                ref={this.fileInput}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={() => this.OnDrop(this.fileInput.current)}
              />
              {this.fileInput?.current?.files[0]?.name}
            </div>
            <div className="controlsDashboard" style={{ marginTop: "2px" }}>
              <input
                value={this.state.importName}
                onChange={(e) =>
                  this.setState({
                    importName: e.target.value,
                  })
                }
                id="importName"
                className="floatLabel"
                style={{
                  fontSize: "15px",
                  borderRadius: "4px",
                  padding: "8px",
                  border: "1px solid #c5c5d3",
                  paddingTop: "13px",
                  paddingBottom: "5px",
                  minWidth: "250px",
                  marginRight: "10px",
                }}
              />
              <label
                for="importName"
                className="activeLabel"
                style={{ fontSize: "12px" }}
              >
                What would you like to name this import?
              </label>
            </div>
          </div>
        </form>
        <TaskTourImport tourComplete={this.state.tourComplete} />
        <div style={{ padding: "5px" }}>
          {this.state.data.length > 0 &&
            Object.keys(this.state.data[0]).map((key, i) => (
              <div
                key={i}
                style={{
                  display: "inline-block",
                  margin: "10px",
                  padding: "15px",
                  border: "2px solid #E3E3E3",
                  borderRadius: "8px",
                }}
              >
                <span>
                  <h4
                    style={{
                      margin: "6px auto",
                      fontWeight: "500",
                      fontSize: "12px",
                      lineHeight: "150%",
                      color: "#0C0D0D",
                    }}
                  >
                    Sheet field
                  </h4>{" "}
                  <div
                    style={{
                      fontWeight: "500",
                      fontSize: "14px",
                      lineHeight: "150%",
                      color: "#0C0D0D",
                    }}
                  >
                    {key}
                  </div>
                </span>
                <h4
                  style={{
                    margin: "6px auto",
                    fontWeight: "500",
                    fontSize: "12px",
                    lineHeight: "150%",
                    color: "#0C0D0D",
                  }}
                >
                  Salesdash field
                </h4>
                <select
                  name="headers"
                  onChange={(e) => this.headersChangeHandler(key, e)}
                  style={{
                    border: "1px solid #A7ABAA",
                    borderRadius: "8px",
                    padding: "7px",
                    maxWidth: "210px",
                  }}
                >
                  <option value="">Do not import</option>
                  <optgroup label="Default Fields">
                    {Object.entries(headerMapping)
                      .filter((acc) => {
                        if (this.importType === "contact") {
                          if (
                            Object.values(this.state.headersMapping).includes(
                              "account_name"
                            )
                          ) {
                            return acc[0] !== "carrier_name";
                          } else if (
                            Object.values(this.state.headersMapping).includes(
                              "carrier_name"
                            )
                          ) {
                            return acc[0] !== "account_name";
                          }
                        }
                        return acc;
                      })
                      // .filter((acc) => {
                      //   if (this.importType === "account") {
                      //     if (
                      //       !this.props.companyOnboarding
                      //         ?.is_multiple_account_owner_enabled
                      //     ) {
                      //       return acc[0] !== "shared_owners";
                      //     }
                      //   }
                      //   return acc;
                      // })
                      .map((acc) => {
                        return (
                          (!Object.values(this.state.headersMapping).includes(
                            acc[0]
                          ) ||
                            this.state.headersMapping[key] === acc[0]) && (
                            <option value={acc[0]}>{acc[1]}</option>
                          )
                        );
                      })}
                  </optgroup>
                  {this.state.fieldsData.map((group) => {
                    const { label, fields, meta, id } = group;
                    return (
                      (meta === null || meta.visible_in_form === true) && (
                        <optgroup label={label} key={id + label}>
                          {fields?.map((field) => {
                            const { id, label, name } = field;
                            return (
                              (!Object.values(
                                this.state.headersMapping
                              ).includes(name) ||
                                this.state.headersMapping[key] === name) && (
                                <option key={id} value={name}>
                                  {label}
                                </option>
                              )
                            );
                          })}
                        </optgroup>
                      )
                    );
                  })}
                </select>
              </div>
            ))}
        </div>
        <table className="accountList__table">
          <thead>
            <tr>
              {Object.values(this.state.headersMapping)
                .filter((header) => header !== "")
                .map((header) => {
                  return <th>{headerKeyValueMappings[header]}</th>;
                })}
            </tr>
          </thead>
          <tbody>
            {this.state.data.slice(0, 5).map((obj, index) => (
              <tr key={index}>
                {Object.keys(this.state.headersMapping)
                  .filter((key) => this.state.headersMapping[key] !== "")
                  .map((key) => (
                    <td>{obj[key]}</td>
                  ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    companyOnboarding: state.login.companyOnboarding,
    authUser: state.login.authUser,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};

export default connect(MSP, MDP)(ImportPage);
