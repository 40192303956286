import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import axios from "axios";
import Pagination from "react-js-pagination";
import { importTypes } from "./constants/importTypes";
import AccountsImportTemplate from "../../documents/AccountsImportTemplate.csv";
import ContactsImportTemplate from "../../documents/ContactsImportTemplate.csv";
import CarriersImportTemplate from "../../documents/CarriersImportTemplate.csv";
import DealsImportTemplate from "../../documents/DealsImportTemplate.csv";
// Redux stuff
import { connect } from "react-redux";
import { companyOnboardingSet, setTab, setActiveTabIndex } from "../../redux";
import YoutubePlayer from "../YoutubePlayer";

const Import = (props) => {
  const [history, setHistory] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [total, setTotal] = useState(0);
  const [showYoutubeVideo, setShowYoutubeVideo] = useState(false);
  const fetchHistory = (pageParam = activePage) => {
    axios({
      method: "GET",
      url: `/import/history?_limit=30&_page=${pageParam}`,
    }).then((res) => {
      setHistory(res.data.history);
      setTotal(res.data.total);
    });
  };
  useEffect(() => {
    fetchHistory();
  }, []);
  useEffect(() => {
    const intervalId = setInterval(fetchHistory, 15000);
    return () => clearInterval(intervalId);
  }, []);

  const handleDownload = (importType) => {
    // The relative path to the CSV file in the public directory
    const filePath =
      importType === "accounts"
        ? AccountsImportTemplate
        : importType === "carriers"
        ? CarriersImportTemplate
        : importType === "deals"
        ? DealsImportTemplate
        : ContactsImportTemplate;

    // Create a temporary link element
    const link = document.createElement("a");
    link.href = filePath;
    link.download = `${importType}ImportTemplate.csv`; // The name for the downloaded file
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <div style={{ margin: "auto 30px" }}>
      {showYoutubeVideo && (
        <YoutubePlayer
          src="https://www.youtube.com/embed/dUY5d0OzQSw"
          closeIframe={() => setShowYoutubeVideo(false)}
        />
      )}
      <div
        style={{
          borderBottom: "1px solid #E3E3E3",
          marginBottom: "10px",
        }}
      >
        <div style={{ display: "inline-block" }}>
          <h2 style={{ marginBottom: "0px" }}>
            Import Data{" "}
            <button
              type="button"
              onClick={() => setShowYoutubeVideo(true)}
              className="button-no-border-red"
              style={{ color: "#61c99d", marginLeft: "20px" }}
            >
              <i className="fa fa-video-camera" aria-hidden="true"></i>
              &nbsp; Walk-through video
            </button>
          </h2>
          <p>Choose to upload Accounts or Deals in Salesdash</p>
        </div>
      </div>
      <div className="grid-container-1" style={{ marginTop: "30px" }}>
        {importTypes
          ?.filter((type) =>
            props.companyOnboarding.industry !== "Freight Brokerage"
              ? type.label !== "Import Carriers"
              : type
          )
          ?.map((type) => (
            <div style={{ height: "190px", width: "300px", padding: "15px" }}>
              <div
                style={{
                  border: "2px dashed #E3E3E3",
                  borderRadius: "8px",
                  height: "160px",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "40%",
                    left: "5%",
                    right: "5%",
                    transform: "translateY(-50%)",
                    textAlign: "center",
                  }}
                >
                  <h3
                    style={{
                      textTransform: "capitalize",
                      margin: "5px 0",
                      marginTop: "10px",
                    }}
                  >
                    {type.type}{" "}
                  </h3>
                  <span
                    style={{
                      color: "#4A9876",
                      fontSize: "11px",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => handleDownload(type.type)}
                  >
                    Click to download {type.type} Import Template
                  </span>
                  <p
                    style={{
                      marginBottom: "15px",
                      marginTop:
                        type.type === "accounts" || type.type === "contacts"
                          ? "3px"
                          : "5px",
                    }}
                  >
                    {type.description}
                  </p>
                  <Link to={type.link} style={type.style}>
                    <img
                      src={type.image}
                      alt=""
                      height="18px"
                      style={{
                        transform: "translateY(3px)",
                        marginRight: "5px",
                      }}
                    />
                    {type.label}
                  </Link>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div>
        <h2>Import History</h2>
        <table
          className="accountList__table"
          style={{ marginTop: "20px", width: "93vw" }}
        >
          <thead>
            <tr>
              <th>Report</th>
              <th>Type</th>
              <th>Upload Result</th>
              <th>Uploaded By</th>
              <th>Status</th>
              <th>Total Rows</th>
              <th>Total Created</th>
              <th>Total Failed</th>
            </tr>
          </thead>
          <tbody>
            {history?.map((his) => {
              const {
                id,
                type,
                report_id,
                upload_result_file_url,
                uploaded_by_name,
                status,
                total_rows,
                total_created,
                total_failed,
                report_name,
              } = his;
              let newTabData = {
                type: "report",
                id: report_id,
                name: report_name,
              };
              return (
                <tr key={id}>
                  <td
                    style={{ cursor: "pointer", color: "#41BE89" }}
                    onClick={(e) => {
                      if (e.metaKey || e.ctrlKey) {
                        props.setTab({ ...newTabData, blank: true });
                      } else {
                        props.setActiveTabIndex(props.totalTabs + 1);
                        props.setTab(newTabData);
                        props.history.push("/active-tabs");
                      }
                    }}
                  >
                    {report_name}
                  </td>
                  <td style={{ textTransform: "capitalize" }}>{type}</td>
                  <td>
                    <a
                      href={upload_result_file_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ cursor: "pointer", color: "#41BE89" }}
                    >
                      File URL
                    </a>
                  </td>
                  <td>{uploaded_by_name}</td>
                  <td>
                    {status === "Completed" && (
                      <CheckCircleIcon
                        sx={{
                          fontSize: 18,
                          color: "#41be89",
                          transform: "translateY(4px)",
                          marginRight: "5px",
                        }}
                      />
                    )}
                    {status}
                    {status === "Pending" && <LinearProgress color="warning" />}
                  </td>
                  <td>{total_rows}</td>
                  <td>{total_created}</td>
                  <td>{total_failed}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Pagination
          activePage={activePage}
          itemsCountPerPage={30}
          totalItemsCount={total}
          pageRangeDisplayed={5}
          onChange={(page) => {
            setActivePage(page);
            fetchHistory(page);
          }}
          itemClass="page-item"
          linkClass="page-link"
          hideDisabled
        />
      </div>
    </div>
  );
};

const MSP = (state) => {
  return {
    companyOnboarding: state.login.companyOnboarding,
    totalTabs: state.tabsState.tabs.length,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};

export default connect(MSP, MDP)(Import);
