import {
  FETCH_CARRIER_CUSTOM_FIELDS_REQUEST,
  FETCH_CARRIER_CUSTOM_FIELDS_SUCCESS,
  FETCH_CARRIER_CUSTOM_FIELDS_FAILURE,
} from "./types";

import axios from "axios";
import { BASE_URL } from "../../config";

const fetchAllCarrierCustomFieldsRequest = () => {
  return {
    type: FETCH_CARRIER_CUSTOM_FIELDS_REQUEST,
  };
};
const fetchAllCarrierCustomFieldsSuccess = (data) => {
  return {
    type: FETCH_CARRIER_CUSTOM_FIELDS_SUCCESS,
    payload: data,
  };
};
const fetchAllCarrierCustomFieldsFailure = (err) => {
  return {
    type: FETCH_CARRIER_CUSTOM_FIELDS_FAILURE,
    payload: err,
  };
};

// Fetch All CarrierCustomFields
export const fetchAllCarrierCustomFields = () => {
  return (dispatch) => {
    dispatch(fetchAllCarrierCustomFieldsRequest());
    axios({
      method: "GET",
      url: `${BASE_URL}/fields/carrier`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
    })
      .then((res) => {
        const data = res.data.fields;
        dispatch(fetchAllCarrierCustomFieldsSuccess(data));
      })
      .catch((err) => {
        const errorMsg = err.response?.data?.message;
        dispatch(fetchAllCarrierCustomFieldsFailure(errorMsg));
      });
  };
};
