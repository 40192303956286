import {
  FETCH_CONTACT_CUSTOM_FIELDS_REQUEST,
  FETCH_CONTACT_CUSTOM_FIELDS_SUCCESS,
  FETCH_CONTACT_CUSTOM_FIELDS_FAILURE,
} from "./types";

import axios from "axios";
import { BASE_URL } from "../../config";

const fetchAllContactCustomFieldsRequest = () => {
  return {
    type: FETCH_CONTACT_CUSTOM_FIELDS_REQUEST,
  };
};
const fetchAllContactCustomFieldsSuccess = (data) => {
  return {
    type: FETCH_CONTACT_CUSTOM_FIELDS_SUCCESS,
    payload: data,
  };
};
const fetchAllContactCustomFieldsFailure = (err) => {
  return {
    type: FETCH_CONTACT_CUSTOM_FIELDS_FAILURE,
    payload: err,
  };
};

// Fetch All ContactCustomFields
export const fetchAllContactCustomFields = () => {
  return (dispatch) => {
    dispatch(fetchAllContactCustomFieldsRequest());
    axios({
      method: "GET",
      url: `${BASE_URL}/fields/contact`,
      headers: {
        Authorization:
          "Bearer " +
          JSON.parse(localStorage.getItem("authToken"))?.access_token,
      },
    })
      .then((res) => {
        const data = res.data.fields;
        dispatch(fetchAllContactCustomFieldsSuccess(data));
      })
      .catch((err) => {
        const errorMsg = err.response?.data?.message;
        dispatch(fetchAllContactCustomFieldsFailure(errorMsg));
      });
  };
};
