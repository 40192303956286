import React, { useState, useCallback, useEffect, useRef } from "react";
import CustomerIntro from "./CustomerIntro";
import ActivityLog from "./ActivityLog";
import ActivityForm from "./ActivityForm";
import "../sass/CustomerProfile.scss";
import Confetti from "react-confetti";
import { NotificationManager } from "react-notifications";

import axios from "axios";
import AllSequenceModal from "./Sequencing/AllSequenceModal";
import SaveEmailModal from "./SendEmail/SaveEmailModal";
import SendSequenceModal from "./Sequencing/SendSequenceModal";
import EditSequenceModal from "./Sequencing/EditSequenceModal";
import BillingCreditModal from "./Reports/BillingCreditModal";
import BillingRedirectModal from "./Reports/BillingRedirectModal";
import PostMarkAppModal from "./Reports/PostMarkAppModal";
import PostMarkAppVerificationModal from "./Reports/PostMarkAppVerificationModal";
import PersonalizedVarsModal from "./SendEmail/PersonalizedVarsModal";

// Redux stuff
import { connect } from "react-redux";
import { authUserSet, setTab, setActiveTabIndex } from "../redux";
import EditLanePanel from "./Lanes/EditLanePanel";
import AddLanePanel from "./Lanes/AddLanePanel";
import NextRecordNavigation from "./NextRecordNavigation";

const CustomerProfile = ({
  accountId,
  customerName,
  tabIndex,
  deleteTab,
  authUser,
  activities,
  showTaskArrow,
  tasks,
  setTab,
  setActiveTabIndex,
  history,
  totalTabs,
  taskIndex,
}) => {
  const [showOpportunityModal, setShowOpportunityModal] = useState(false);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [showAllSequenceModal, setShowAllSequenceModal] = useState(false);
  const [showSaveEmailModal, setShowSaveEmailModal] = useState(false);
  const [showSendSequenceModal, setShowSendSequenceModal] = useState(false);
  const [showEditSequenceModal, setShowEditSequenceModal] = useState(false);
  const [showCreditsModal, setShowCreditsModal] = useState(false);
  const [showRedirectModal, setShowRedirectModal] = useState(false);
  const [showPostMarkModal, setShowPostMarkModal] = useState(false);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [showPersonalizedVarsModal, setShowPersonalizedVarsModal] =
    useState(false);

  const [logs, setLogs] = useState([]);
  const [pinnedActivity, setPinnedActivity] = useState(null);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [accountName, setAccountName] = useState("");
  const [accountMobile, setAccountMobile] = useState("");
  const [restart, setRestart] = useState(false);
  const [callTasks, setCallTasks] = useState(false);
  const [accountEmail, setAccountEmail] = useState(null);
  const [accountData, setAccountData] = useState({});
  const [accountCustomFields, setAccountCustomFields] = useState({});
  const [contactEmailsArray, setContactEmailsArray] = useState([]);
  const [integration, setIntegration] = useState(null);
  const [signatureId, setSignatureId] = useState(null);
  const [signatureVerified, setSignatureVerified] = useState(false);
  const [emailCredits, setEmailCredits] = useState(0);
  const [selectedSequence, setSelectedSequence] = useState({});
  const [templateId, setTemplateId] = useState(null);
  const [personalizedVariablesArray, setPersonalizedVariablesArray] = useState(
    []
  );
  const [recorderLoading, setRecorderLoading] = useState(false);
  const [emailValidationError, setEmailValidationError] = useState(false);
  const [activityLoader, setActivityLoader] = useState(false);
  const [emailSignature, setEmailSignature] = useState("");
  const [addLane, setAddLane] = useState(false);
  const [laneMode, setLaneMode] = useState("create");
  const [laneData, setLaneData] = useState({});
  const [carrierLanes, setCarrierLanes] = useState([]);
  const [totalLanes, setTotalLanes] = useState(0);
  const [opportunities, setOpportunities] = useState([]);
  const [contacts, setContacts] = useState([]);
  const accountMobileRef = useRef(accountMobile);
  const setAccountMobileRef = (data) => {
    accountMobileRef.current = data;
    setAccountMobile(data);
  };

  const emailDecision = () => {
    if (signatureId === null) {
      setShowPostMarkModal(true);
    } else if (!signatureVerified) {
      setShowVerificationModal(true);
    } else if (emailCredits === null || 10 > emailCredits) {
      setShowCreditsModal(true);
    } else {
      setShowAllSequenceModal(true);
    }
  };
  const getSignature = () => {
    axios({
      method: "GET",
      url: `/users/email-signature`,
    }).then((res) => {
      if (res.data.email_signature !== null) {
        setEmailSignature(res.data.email_signature);
      }
    });
  };
  const fetchAllOpportunities = () => {
    axios({
      method: "GET",
      url: `/accounts/${accountId}/opportunities?_limit=100`,
    }).then((res) => {
      setOpportunities(res.data.opportunities);
    });
  };
  const fetchAllContacts = () => {
    axios({
      method: "GET",
      url: `/accounts/${accountId}/contacts?_limit=100`,
    }).then((res) => {
      const data = res.data.contacts;
      setContacts(data);
    });
  };
  const recordChangeHandler = (e) => {
    setAccountEmail(e.target.value);
  };
  const recordSubmitHandler = (e) => {
    e.preventDefault();
    const emailValidator = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,16}$/i;
    if (
      accountEmail !== null &&
      accountEmail !== "" &&
      !emailValidator.test(accountEmail)
    ) {
      setRecorderLoading(false);
      setEmailValidationError(true);
      return false;
    }
    setRecorderLoading(true);
    let customField = accountCustomFields;
    for (let i in customField) {
      if (customField[i] === "") {
        customField[i] = null;
      }
    }
    axios({
      method: "PUT",
      url: `/accounts/${accountId}`,
      data: {
        name: accountData.name,
        state: accountData.state,
        country: accountData.country,
        zip: accountData.zip,
        city: accountData.city,
        address: accountData.address,
        source: accountData.source,
        email: accountEmail,
        formatted_phone: accountData.formatted_phone,
        phone_ext: accountData.phone_ext,
        website: accountData.website
          ? accountData.website.startsWith("https://") ||
            accountData.website.startsWith("http://")
            ? accountData.website
            : "https://" + accountData.website
          : null,
        description: accountData.description,
        preferred_communication: (
          accountData.preferred_communication || []
        ).filter((com) => com !== ""),
        custom_fields: customField,
      },
    })
      .then((response) => {
        NotificationManager.success("Account edited successfully.");
        setRecorderLoading(false);
        setEmailValidationError(false);
        setShowSaveEmailModal(false);
        setShowAllSequenceModal(true);
      })
      .catch((error) => {
        setRecorderLoading(false);
        if (error.response.status === 409) {
          NotificationManager.error(error.response.data.message);
        } else {
          NotificationManager.error("Error editing account.");
        }
      });
  };

  const checkUserProfile = () => {
    axios({
      method: "GET",
      url: `/users/profile`,
    }).then((res) => {
      setSignatureVerified(res.data.postmark_signature_verified);
      setSignatureId(res.data.postmark_signature_id);
      setEmailCredits(res.data.postmark_email_balance);
    });
  };
  const checkVerification = () => {
    axios({
      method: "GET",
      url: `/users/profile`,
    }).then((res) => {
      verifySignature(res.data.postmark_signature_verified);
      setSignatureVerified(res.data.postmark_signature_verified);
      setSignatureId(res.data.postmark_signature_id);
      setEmailCredits(res.data.postmark_email_balance);
    });
  };
  const verifySignature = (signatureVerified) => {
    if (!signatureVerified) {
      axios({
        method: "PATCH",
        url: `/users/verify-postmark-signature`,
        data: {},
      })
        .then((res) => {
          setShowVerificationModal(false);
          checkUserProfile();
          setShowCreditsModal(true);
        })
        .catch((err) => {
          NotificationManager.error("Email not verified.");
        });
    }
  };
  const setSequenceValue = (value) => {
    axios({
      method: "GET",
      url: `/sequences/${value}?account_id=${accountId}`,
    }).then((res) => {
      setSelectedSequence(res.data.sequence);
      setShowSendSequenceModal(true);
      setShowAllSequenceModal(false);
    });
  };
  const readTemplateVarsValues = (tempId, personalizedVariables) => {
    let url = `/emails/templates/${tempId}?account_id=${accountId}`;
    axios({
      method: "GET",
      url,
    }).then((res) => {
      setShowPersonalizedVarsModal(false);
      let finalContent = res.data.template.body;
      let finalSubject = res.data.template.subject;
      for (let i = 0; i < personalizedVariables.length; i++) {
        finalContent = finalContent.includes(
          `{{ ${personalizedVariables[i].key} }}`
        )
          ? finalContent.replaceAll(
              `{{ ${personalizedVariables[i].key} }}`,
              personalizedVariables[i].value
            )
          : finalContent;
        finalSubject = finalSubject.includes(
          `{{ ${personalizedVariables[i].key} }}`
        )
          ? finalSubject.replaceAll(
              `{{ ${personalizedVariables[i].key} }}`,
              personalizedVariables[i].value
            )
          : finalSubject;
      }
    });
  };
  const accountInfo = () => {
    axios({
      method: "GET",
      url: `/accounts/${accountId}`,
    }).then((res) => {
      setAccountMobileRef(res.data.account.formatted_phone);
    });
  };
  const getAccountLanes = () => {
    axios({
      method: "GET",
      url: `/lanes?account_id=${accountId}`,
    }).then((res) => {
      setCarrierLanes(res.data.lanes);
      setTotalLanes(res.data.total);
    });
  };
  useEffect(() => {
    checkUserProfile();
    getSignature();
    accountInfo();
    getAccountLanes();
    fetchAllOpportunities();
    fetchAllContacts();
  }, []);

  const loadActivities = useCallback(
    (page, fetchType) => {
      setActivityLoader(true);
      let url = `/${"accounts"}/${accountId}/activities?_limit=30&_page=${page}`;
      axios({
        method: "GET",
        url,
        headers: {
          Authorization:
            "Bearer " +
            JSON.parse(localStorage.getItem("authToken"))?.access_token,
        },
      })
        .then((res) => {
          setActivityLoader(false);
          if (res.data.logs.length === 0) setHasMoreItems(false);
          switch (fetchType) {
            case "refetch": {
              setLogs(res.data.logs);
              setHasMoreItems(false);
              setPinnedActivity(res.data.pinned_activity);
              break;
            }
            case "initial":
              setLogs((oldLogs) => [...oldLogs, ...res.data.logs]);
              setHasMoreItems(false);
              setPinnedActivity(res.data.pinned_activity);
              break;
            case "nextPage":
            default:
              setLogs((oldLogs) => [...oldLogs, ...res.data.logs]);
              setPinnedActivity(res.data.pinned_activity);
              if (res.data.logs.length === 0) {
                setHasMoreItems(false);
              } else {
                setHasMoreItems(true);
              }
          }
        })
        .catch((err) => {
          setActivityLoader(false);
        });
      axios({
        method: "GET",
        url: `/accounts/${accountId}`,
      }).then((res) => setAccountName(res.data.account.name));
    },
    [accountId]
  );
  useEffect(() => {
    if (activities.fetchActivity === true) {
      loadActivities(1, "refetch");
    }
  }, [activities.fetchActivity]);
  const getConfetti = () => {
    setShowConfetti(true);
  };
  return (
    <div>
      <div className="customerProfile" id={`accountProfile${accountId}`}>
        {showConfetti && (
          <Confetti
            width={window.innerWidth / 1.1}
            height={window.innerHeight}
            numberOfPieces={400}
            recycle={false}
          />
        )}
        <div className="row">
          {showTaskArrow && (
            <NextRecordNavigation
              tasks={tasks}
              setTab={setTab}
              setActiveTabIndex={setActiveTabIndex}
              history={history}
              totalTabs={totalTabs}
              recordType="account"
              recordId={accountId}
              taskIndex={taskIndex}
            />
          )}
          <div className="col-1-of-3">
            <CustomerIntro
              customerId={accountId}
              showOpportunityModal={showOpportunityModal}
              setShowOpportunityModal={setShowOpportunityModal}
              customerName={accountName}
              tabIndex={tabIndex}
              deleteTab={deleteTab}
              showTaskModal={showTaskModal}
              setShowTaskModal={setShowTaskModal}
              restart={restart}
              setRestart={setRestart}
              setCallTasks={setCallTasks}
              callTasks={callTasks}
              loadActivities={loadActivities}
              setAccountEmail={setAccountEmail}
              setContactEmailsArray={setContactEmailsArray}
              setIntegration={setIntegration}
              getConfetti={getConfetti}
              setAccountData={setAccountData}
              setAccountCustomFields={setAccountCustomFields}
              setAddLane={setAddLane}
              setLaneData={setLaneData}
              setLaneMode={setLaneMode}
              addLane={addLane}
              carrierLanes={carrierLanes}
              totalLanes={totalLanes}
              fetchAllOpportunities={fetchAllOpportunities}
              opportunities={opportunities}
              fetchAllContacts={fetchAllContacts}
              contacts={contacts}
            />
          </div>
          <AllSequenceModal
            showOpportunityModal={showAllSequenceModal}
            setShowOpportunityModal={() => setShowAllSequenceModal(true)}
            handleCloseModal={() => setShowAllSequenceModal(false)}
            setSequenceValue={setSequenceValue}
            handleOpenEditSequenceModal={() => setShowEditSequenceModal(true)}
          />
          <SaveEmailModal
            showModal={showSaveEmailModal}
            handleCloseModal={() => setShowSaveEmailModal(false)}
            email={accountEmail}
            changeHandler={recordChangeHandler}
            modalHeight={45}
            modalTop={15}
            loading={recorderLoading}
            submitHandler={recordSubmitHandler}
            error={emailValidationError}
          />
          <SendSequenceModal
            showOpportunityModal={showSendSequenceModal}
            setShowOpportunityModal={() => setShowSendSequenceModal(true)}
            handleCloseModal={() => setShowSendSequenceModal(false)}
            selectedSequence={selectedSequence}
            toEmail={accountEmail ? [accountEmail] : []}
            accountId={accountId}
            fromEmail={authUser.email}
            handleCloseEmailModal={() => setShowSendSequenceModal(false)}
            emailType={"account_id"}
            loadActivities={loadActivities}
            emailSignature={emailSignature}
          />
          <EditSequenceModal
            showOpportunityModal={showEditSequenceModal}
            setShowOpportunityModal={() => setShowEditSequenceModal(true)}
            handleCloseModal={() => setShowEditSequenceModal(false)}
            selectedSequence={selectedSequence}
          />
          <BillingCreditModal
            showOpportunityModal={showCreditsModal}
            setShowOpportunityModal={() => setShowCreditsModal(true)}
            handleCloseModal={() => setShowCreditsModal(false)}
            handleEmailOpenModal={() => setShowAllSequenceModal(true)}
            checkUserProfile={checkUserProfile}
          />
          <BillingRedirectModal
            showOpportunityModal={showRedirectModal}
            setShowOpportunityModal={() => setShowRedirectModal(true)}
            handleCloseModal={() => setShowRedirectModal(false)}
          />
          <PostMarkAppModal
            showOpportunityModal={showPostMarkModal}
            setShowOpportunityModal={() => setShowPostMarkModal(true)}
            handleCloseModal={() => setShowPostMarkModal(false)}
            handleVerificationOpenModal={() => setShowVerificationModal(true)}
            checkUserProfile={checkUserProfile}
          />
          <PostMarkAppVerificationModal
            showOpportunityModal={showVerificationModal}
            setShowOpportunityModal={() => setShowVerificationModal(true)}
            handleCloseModal={() => setShowVerificationModal(false)}
            checkUserProfile={checkUserProfile}
            signatureVerified={signatureVerified}
            handleEmailOpenModal={() => setShowAllSequenceModal(true)}
            checkVerification={checkVerification}
          />
          <PersonalizedVarsModal
            showOpportunityModal={showPersonalizedVarsModal}
            setShowOpportunityModal={() => setShowPersonalizedVarsModal(true)}
            handleCloseModal={() => setShowPersonalizedVarsModal(false)}
            readTemplateVarsValues={readTemplateVarsValues}
            templateId={templateId}
            personalizedVariablesArray={personalizedVariablesArray}
          />
          {addLane && laneMode === "edit" ? (
            <div className="col-2-of-3" style={{ marginRight: "0" }}>
              <EditLanePanel
                accountId={accountId}
                setAddLane={setAddLane}
                getCarrierLanes={getAccountLanes}
                setLaneData={setLaneData}
                laneData={laneData}
              />
            </div>
          ) : addLane ? (
            <div className="col-2-of-3" style={{ marginRight: "0" }}>
              <AddLanePanel
                accountId={accountId}
                setAddLane={setAddLane}
                getCarrierLanes={getAccountLanes}
              />
            </div>
          ) : null}
          <div
            className="col-1-of-3"
            style={addLane ? { display: "none" } : {}}
          >
            <ActivityLog
              entityId={accountId}
              customerName={accountName}
              entityType="account"
              // New props
              loadActivities={loadActivities}
              hasMoreItems={hasMoreItems}
              logs={logs}
              integration={integration}
              pinnedActivity={pinnedActivity}
              activityLoader={activityLoader}
              toEmail={accountEmail ? [accountEmail] : []}
            />
          </div>
          <div
            className="col-1-of-3"
            style={addLane ? { display: "none" } : {}}
          >
            <ActivityForm
              customerId={accountId}
              showTaskModal={showTaskModal}
              setShowTaskModal={setShowTaskModal}
              customerName={accountName}
              // New props
              loadActivities={loadActivities}
              restart={restart}
              setRestart={setRestart}
              callTasks={callTasks}
              accountEmail={accountEmail}
              contactEmailsArray={contactEmailsArray}
              integration={integration}
              emailDecision={emailDecision}
              fetchAllOpportunities={fetchAllOpportunities}
              opportunities={opportunities}
              fetchAllContacts={fetchAllContacts}
              contacts={contacts}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const MSP = (state) => {
  return {
    authUser: state.login.authUser,
    activities: state.activities,
    totalTabs: state.tabsState.tabs.length,
  };
};

const MDP = (dispatch) => {
  return {
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
  };
};

export default connect(MSP, MDP)(React.memo(CustomerProfile));
