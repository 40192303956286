import React, { Component } from "react";
import axios from "axios";
import "../../sass/AccountsList.scss";
import Pagination from "react-js-pagination";
import moment from "moment";
import { debounce } from "lodash";
import CreateContact from "./CreateContact";
import { Col, Row } from "react-grid-system";
import { NotificationManager } from "react-notifications";
import { AsYouType } from "libphonenumber-js";

// Redux stuff
import { connect } from "react-redux";
import {
  setTab,
  setActiveTabIndex,
  companyOnboardingSet,
  authUserSet,
} from "../../redux";
import DropdownDashboard from "../Dropdown/DropdownDashboard";
import CreateContactModal from "../CreateModal/CreateContactModal";

class ContactsList extends Component {
  state = {
    contacts: [],
    query: "",
    activePage: 1,
    total: 0,
    showContactModal: false,
    showNewContactModal: false,
    sortDirection: true,
    isApplying: true,
    inputFocused: {
      isTrue: false,
      id: "",
      name: "",
    },
    showNameInput: {
      isTrue: false,
      id: "",
    },
    userName: this.props.authUser.name,
    user_ids: this.props.userId || this.props.authUser.id,
    displayMenu: false,
    displayUserName: this.props.userName || this.props.authUser.name,
  };

  handleOpenContactModal = () => {
    this.setState({
      showContactModal: true,
    });
  };
  handleCloseContactModal = () => {
    this.setState({
      showContactModal: false,
    });
  };
  handleOpenNewContactModal = () => {
    this.setState({
      showNewContactModal: true,
    });
  };
  handleCloseNewContactModal = () => {
    this.setState({
      showNewContactModal: false,
    });
  };

  fetchAccounts = debounce((query, page = 1, sortKey = "created_date") => {
    let url = `/contacts?_limit=30&_page=${page}`;
    if (query) {
      url += `&name=${encodeURIComponent(query)}`;
    }
    if (this.state.user_ids) {
      url += `&user_ids=${this.state.user_ids}`;
    }
    if (sortKey) {
      const dir = this.state.sortDirection === true ? "desc" : "asc";
      url += `&sort_key=${sortKey}&sort_dir=${dir}`;
    }
    this.setState({ isApplying: true });
    axios({
      method: "GET",
      url,
    })
      .then((response) => {
        this.setState({
          contacts: response.data.contacts,
          total: response.data.total,
          isApplying: false,
        });
      })
      .catch((error) => this.setState({ isApplying: false }));
  }, 500);

  logCall = (description, selectedContact) => {
    axios({
      method: "POST",
      url: `/activities/create`,
      data: {
        name: `${description?.direction} Call`,
        description: `${description?.direction} Call made at ${Date(
          description?.startTime
        )} to <span style="color: #62ca9d;">${
          description?.to?.phoneNumber
        }</span>`,
        category_id: 1,
        contact_id: selectedContact.id,
        type: "activity_logged",
      },
    }).then((res) => {
      NotificationManager.success("Call logged.");
    });
  };
  handleClickToDial = (e) => {
    const data = e.data;
    if (
      data &&
      data.type === "rc-active-call-notify" &&
      data.call &&
      data.call.terminationType === "final"
    ) {
      const selectedContact = this.state.contacts?.find((table) =>
        data.call.to.phoneNumber.includes(table.formatted_phone)
      );
      if (selectedContact) {
        this.logCall(data.call, selectedContact);
      }
    }
  };
  componentDidMount() {
    window.addEventListener("message", this.handleClickToDial);
    this.fetchAccounts();
  }
  componentWillUnmount() {
    window.removeEventListener("message", this.handleClickToDial);
  }

  tableHeaders = [
    {
      value: "contact_name",
      label: "Name",
      sort: true,
      editable: true,
      type: "text",
    },
    {
      value: "email",
      label: "Email",
      sort: true,
      editable: true,
      type: "text",
    },
    {
      value: "formatted_phone",
      label: "Phone",
      sort: true,
      editable: true,
      type: "text",
    },
    {
      value: "role",
      label: "Job Title",
      sort: true,
      editable: true,
      type: "text",
    },
    {
      value: "source",
      label: "Source",
      sort: true,
      editable: true,
      type: "dropdown",
    },
    {
      value: "created_date",
      label: "created date",
      sort: true,
      editable: false,
      type: "date",
    },
    {
      value: "last_activity_date",
      label: "last activity date",
      sort: true,
      editable: true,
      type: "date",
    },
  ];

  renderTableData = (contactData, tableData, name, value, dateFormat) => {
    let newTabData = {
      type: "contact",
      id: contactData.id,
      name: contactData.name,
    };
    let renderItem = <td>{value}</td>;
    if (name === "name") {
      renderItem = (
        <td className="inlineReportEdit">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span
              style={
                this.state.inputFocused.id === contactData.id &&
                this.state.inputFocused.isTrue &&
                this.state.inputFocused.name === name
                  ? { display: "none" }
                  : { cursor: "pointer", color: "#41BE89" }
              }
              onClick={(e) => {
                if (e.metaKey || e.ctrlKey) {
                  this.props.setTab({ ...newTabData, blank: true });
                } else {
                  this.props.setActiveTabIndex(this.props.totalTabs + 1);
                  this.props.setTab(newTabData);
                  this.props.history.push("/active-tabs");
                }
              }}
            >
              {value}
            </span>
            <button
              className="button-sm-secondary"
              style={
                this.state.inputFocused.id === contactData.id &&
                this.state.inputFocused.isTrue &&
                this.state.inputFocused.name === name
                  ? { display: "none" }
                  : {}
              }
              onClick={() =>
                this.setState({
                  inputFocused: {
                    id: contactData.id,
                    isTrue: true,
                    name: name,
                  },
                  showNameInput: {
                    isTrue: true,
                    id: contactData.id,
                  },
                })
              }
            >
              Edit
            </button>
            <input
              value={value}
              style={
                this.state.showNameInput.id === contactData.id &&
                this.state.showNameInput.isTrue === true
                  ? {
                      display: "inline-block",
                      fontWeight: "500",
                      width: "96%",
                      padding: "3px",
                      border: "1px solid #e3e3e3",
                    }
                  : {
                      display: "none",
                    }
              }
              onChange={(e) =>
                this.fieldChangeHandler(contactData, name, e.target.value)
              }
              onFocus={() =>
                this.setState({
                  inputFocused: {
                    id: contactData.id,
                    isTrue: true,
                    name: name,
                  },
                })
              }
              onBlur={() =>
                this.setState({
                  inputFocused: {
                    id: "",
                    isTrue: false,
                    name: "",
                  },
                  showNameInput: {
                    isTrue: false,
                    id: "",
                  },
                })
              }
            />
          </div>
        </td>
      );
    } else if (name === "formatted_phone") {
      renderItem = (
        <td className="inlineReportEdit">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span
              style={
                this.state.inputFocused.id === contactData.id &&
                this.state.inputFocused.isTrue &&
                this.state.inputFocused.name === name
                  ? { display: "none" }
                  : { color: "#41BE89" }
              }
            >
              <a
                target="_blank"
                style={{ color: "#41BE89", cursor: "pointer" }}
                data-stringify-link={`tel:${value
                  ?.toString()
                  ?.split("")
                  ?.filter(
                    (num) =>
                      num !== "-" &&
                      num !== " " &&
                      num !== "." &&
                      num !== "(" &&
                      num !== ")"
                  )
                  ?.join("")}`}
                delay="150"
                data-sk="tooltip_parent"
                href={`tel:${value
                  ?.toString()
                  ?.split("")
                  ?.filter(
                    (num) =>
                      num !== "-" &&
                      num !== " " &&
                      num !== "." &&
                      num !== "(" &&
                      num !== ")"
                  )
                  ?.join("")}`}
                rel="noopener noreferrer"
                tabindex="-1"
                data-remove-tab-index="true"
              >
                {value}
              </a>
            </span>
            <button
              className="button-sm-secondary"
              style={
                this.state.inputFocused.id === contactData.id &&
                this.state.inputFocused.isTrue &&
                this.state.inputFocused.name === name
                  ? { display: "none" }
                  : {}
              }
              onClick={() =>
                this.setState({
                  inputFocused: {
                    id: contactData.id,
                    isTrue: true,
                    name: name,
                  },
                  showNameInput: {
                    isTrue: true,
                    id: contactData.id,
                    name,
                  },
                })
              }
            >
              Edit
            </button>
            <input
              value={value}
              style={
                this.state.showNameInput.id === contactData.id &&
                this.state.showNameInput.isTrue === true &&
                this.state.showNameInput.name === name
                  ? {
                      display: "inline-block",
                      fontWeight: "500",
                      width: "96%",
                      padding: "3px",
                      border: "1px solid #e3e3e3",
                    }
                  : {
                      display: "none",
                    }
              }
              onChange={(e) =>
                this.fieldChangeHandler(contactData, name, e.target.value)
              }
              onFocus={() =>
                this.setState({
                  inputFocused: {
                    id: contactData.id,
                    isTrue: true,
                    name: name,
                  },
                })
              }
              onBlur={() =>
                this.setState({
                  inputFocused: {
                    id: "",
                    isTrue: false,
                    name: "",
                  },
                  showNameInput: {
                    isTrue: false,
                    id: "",
                    name: "",
                  },
                })
              }
            />
          </div>
        </td>
      );
    } else if (tableData.type === "text") {
      renderItem = (
        <td className="inlineReportEdit">
          <span
            className="inlineReportEdit-span"
            style={
              this.state.inputFocused.id === contactData.id &&
              this.state.inputFocused.isTrue &&
              this.state.inputFocused.name === name
                ? { display: "none" }
                : {}
            }
          >
            {value}
          </span>
          <input
            className="inlineReportEdit-input"
            value={value}
            onChange={(e) =>
              this.fieldChangeHandler(contactData, name, e.target.value)
            }
            onFocus={() =>
              this.setState({
                inputFocused: {
                  id: contactData.id,
                  isTrue: true,
                  name: name,
                },
              })
            }
            onBlur={() =>
              this.setState({
                inputFocused: {
                  id: "",
                  isTrue: false,
                  name: "",
                },
              })
            }
          />
        </td>
      );
    } else if (tableData.type === "date") {
      renderItem = <td>{value && moment(value).format(dateFormat)}</td>;
    } else if (tableData.type === "dropdown") {
      renderItem = (
        <td className="inlineReportEdit">
          <span
            className="inlineReportEdit-span"
            style={
              this.state.inputFocused.id === contactData.id &&
              this.state.inputFocused.isTrue &&
              this.state.inputFocused.name === name
                ? { display: "none" }
                : {}
            }
          >
            {value}
          </span>
          <select
            className="inlineReportEdit-input"
            value={value}
            onChange={(e) =>
              this.fieldChangeHandler(contactData, name, e.target.value)
            }
            onFocus={() =>
              this.setState({
                inputFocused: {
                  id: contactData.id,
                  isTrue: true,
                  name: name,
                },
              })
            }
            onBlur={() =>
              this.setState({
                inputFocused: {
                  id: "",
                  isTrue: false,
                  name: "",
                },
              })
            }
          >
            <option hidden>-Select-</option>
            <option selected disabled value="">
              -Select-
            </option>
            {this.props.allAccountSources?.map((cat) => (
              <option key={cat.name} value={cat.name}>{cat.name}</option>
            ))}
          </select>
        </td>
      );
    }

    return renderItem;
  };
  fieldChangeHandler = (contact, name, value) => {
    let contactData = contact;
    let formattedValue = value;
    if (name === "formatted_phone") {
      const formatter = new AsYouType("US"); // Initialize AsYouType instance
      formattedValue = formatter.input(value); // Format the input value
    }
    contactData = {
      ...contact,
      [name]: formattedValue === "" ? null : formattedValue,
    };
    this.setState({
      contacts: this.state.contacts?.map((con) => {
        if (con.id === contact.id) {
          con = {
            ...contact,
            [name]: formattedValue === "" ? null : formattedValue,
          };
        }
        return con;
      }),
    });
    this.submitHandler(contactData);
  };
  handleUserId = (user_ids, userName) => {
    this.setState(
      {
        user_ids,
        displayMenu: !this.state.displayMenu,
        displayUserName: userName,
      },
      () => this.fetchAccounts()
    );
  };
  showDropdownMenu = (event) => {
    event.preventDefault();
    this.setState({ displayMenu: !this.state.displayMenu });
  };
  submitHandler = debounce((contactData) => {
    const phoneValidator = /^[0-9.+)( ,-]*$/;
    const emailValidator = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,16}$/i;
    if (
      contactData.formatted_phone !== null &&
      contactData.formatted_phone !== "" &&
      !phoneValidator.test(contactData.formatted_phone)
    ) {
      return false;
    }
    if (
      contactData.email !== null &&
      contactData.email !== "" &&
      !emailValidator.test(contactData.email)
    ) {
      return false;
    }
    if (!contactData.name) {
      return false;
    }
    axios({
      method: "PUT",
      url: `/contacts/${contactData.id}`,
      data: {
        name: contactData.name,
        role: contactData.role,
        source: contactData.source,
        email: contactData.email,
        formatted_phone: contactData.formatted_phone,
        phone_ext: contactData.phone_ext,
        formatted_mobile: contactData.formatted_mobile,
        zip: contactData.zip,
        city: contactData.city,
        address: contactData.address,
        state: contactData.state,
        country: contactData.country,
        date_of_birth: contactData.date_of_birth,
        preferred_communication: contactData.preferred_communication?.filter(
          (pref) => pref !== ""
        ),
        contact_owner_id: contactData.contact_owner_id,
        custom_fields: contactData.custom_fields,
        account_id: contactData.account_id,
      },
    })
      .then((response) => {
        // NotificationManager.success("Contact edited successfully.");
      })
      .catch((error) => {
        if (error?.response?.status === 500) {
          NotificationManager.error("Error editing contact.");
        } else {
          NotificationManager.error(error?.response?.data.message);
        }
      });
  }, 500);

  render() {
    const contact = this.state.contacts;
    const dateFormat =
      this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
        ? "DD/MM/YYYY"
        : "l";
    return (
      <div className="accountList">
        <h1 className="accountList__heading">
          Contacts <span style={{ color: "#62ca9d" }}>{this.state.total}</span>
          <div style={{ float: "right" }}>
            <DropdownDashboard
              handleUserId={this.handleUserId}
              user_ids={this.state.user_ids}
              userName={this.state.userName}
              userId={this.props.authUser.id}
              displayMenu={this.state.displayMenu}
              showDropdownMenu={this.showDropdownMenu}
              displayUserName={this.state.displayUserName}
              userRole={this.props.authUser.role}
            />
          </div>
        </h1>
        <div className="accountList__function">
          <input
            type="search"
            placeholder="&#xf002;&nbsp;&nbsp;&nbsp;Search Contacts"
            className="reports-search"
            value={this.state.query}
            onChange={({ target: { value } }) => {
              this.fetchAccounts(value, 1);
              this.setState({ query: value, activePage: 1 });
            }}
          />
          <button
            className="button-md"
            type="button"
            onClick={
              this.props.isContactFieldsModified
                ? this.handleOpenNewContactModal
                : this.handleOpenContactModal
            }
            style={{ float: "right" }}
          >
            + Add Contact
          </button>
        </div>
        <Pagination
          activePage={this.state.activePage}
          itemsCountPerPage={30}
          totalItemsCount={this.state.total}
          pageRangeDisplayed={5}
          onChange={(page) => {
            this.setState({ activePage: page });
            this.fetchAccounts(null, page);
          }}
          itemClass="page-item"
          linkClass="page-link"
          hideDisabled
        />
        {this.state.isApplying ? (
          <div class="load-wrapp">
            <div class="load-3">
              <div class="line"></div>
              <div class="line"></div>
              <div class="line"></div>
            </div>
          </div>
        ) : this.state.isApplying === false && this.state.total === 0 ? (
          <div
            style={{
              textAlign: "center",
              marginTop: "50px",
              fontWeight: "500",
            }}
          >
            No contacts found
          </div>
        ) : (
          <table className="accountList__table">
            <thead>
              <tr>
                {this.tableHeaders.map((headers) => (
                  <th key={headers.label}>
                    <Row>
                      <Col lg={9} xl={9}>
                        {headers.label}
                      </Col>
                      <Col lg={3} xl={3}>
                        {headers.sort && (
                          <button
                            type="button"
                            style={{
                              background: "transparent",
                              border: "none",
                              cursor: "pointer",
                              outline: "none",
                              color: "#657885",
                            }}
                            onClick={() =>
                              this.setState(
                                {
                                  sortDirection: !this.state.sortDirection,
                                },
                                this.fetchAccounts(
                                  null,
                                  this.state.activePage,
                                  headers.value
                                )
                              )
                            }
                          >
                            <i className="fa fa-long-arrow-up"></i>
                            <i className="fa fa-long-arrow-down"></i>
                          </button>
                        )}
                      </Col>
                    </Row>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {contact?.map((contact) => (
                <tr key={contact.id}>
                  {this.tableHeaders?.map((header) =>
                    this.renderTableData(
                      contact,
                      header,
                      header.value === "contact_name" ? "name" : header.value,
                      contact[
                        header.value === "contact_name" ? "name" : header.value
                      ],
                      dateFormat
                    )
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <CreateContact
          showOpportunityModal={this.state.showContactModal}
          setShowOpportunityModal={this.handleOpenContactModal}
          handleCloseModal={this.handleCloseContactModal}
        />
        <CreateContactModal
          showOpportunityModal={this.state.showNewContactModal}
          setShowOpportunityModal={this.handleOpenNewContactModal}
          handleCloseModal={this.handleCloseNewContactModal}
        />
        <Pagination
          activePage={this.state.activePage}
          itemsCountPerPage={30}
          totalItemsCount={this.state.total}
          pageRangeDisplayed={5}
          onChange={(page) => {
            this.setState({ activePage: page });
            this.fetchAccounts(null, page);
          }}
          itemClass="page-item"
          linkClass="page-link"
          hideDisabled
        />
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    totalTabs: state.tabsState.tabs.length,
    companyOnboarding: state.login.companyOnboarding,
    authUser: state.login.authUser,
    isContactFieldsModified: state.allContactFields.isModified,
    allAccountSources: state.allAccountSources.accountSources,
  };
};

const MDP = (dispatch) => {
  return {
    setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
  };
};

export default connect(MSP, MDP)(ContactsList);
