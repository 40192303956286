import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";
// Sentry tracing for errors
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import axios from "axios";
import { BASE_URL } from "./config";

axios.defaults.baseURL = BASE_URL;
if (window?.location?.hostname?.endsWith(".salesdashcrm.com")) {
  Sentry.init({
    dsn: "https://c74c737b5f5a4dad8d5afcdabb18b87f@o1298339.ingest.sentry.io/6528568",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
