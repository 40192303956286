import React, { useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";

// SD files
import Dashboard from "../components/Dashboard";
import AccountsList from "../components/AccountsList";
import DashboardCustom from "../components/DashboardCustom/DashboardCustom";
import AccountCustom from "../components/AccountCustom";
import OpportunityCustom from "../components/OpportunityCustom";
import Reports from "../components/Reports";
import ReportsAccountSave from "../components/Reports/ReportsAccountSave";
import TasksCalendar from "../components/TasksCalendar";
import ReportsDealSave from "../components/Reports/ReportsDealSave";
import ReportsActivitySave from "../components/Reports/ReportsActivitySave";
import AnalyticsComingSoon from "../components/AnalyticsComingSoon";
import Import from "../components/Import/Import";
import ImportDeal from "../components/Import/ImportDeal";
import index from "../components/Pricing";
import Users from "../components/Teams/Users";
import Integration from "../components/Integration/Integration";
import Teams from "../components/Teams/Teams";
import Roles from "../components/Teams/Roles";
import ImportUser from "../components/Teams/ImportUser";
import TeamsDetailTable from "../components/Teams/TeamsDetailTable";
import Cards from "../components/Payment/Cards";
import ContactsList from "../components/Contacts/ContactsList";
import ContactsCustom from "../components/Contacts/ContactsCustom";
import ReportsContactSave from "../components/Reports/ReportsContactSave";
import TasksList from "../components/Tasks/TasksList";
import DealsList from "../components/Deals/DealsList";
import DummyProfile from "../components/Dummy/DummyProfile";
import ActivityList from "../components/ActivityList";
import ContactForm from "../components/WebForms/ContactForm";
import ProfilePage from "../components/Profile/ProfilePage";
import TwoFaPage from "../components/Profile/TwoFaPage";
import CompanySettings from "../components/Currency/CompanySettings";
import NotificationSetting from "../components/NotificationSetting/NotificationSetting";
import Campaign from "../components/Campaign/Campaign";
import Sequencing from "../components/Sequencing/Sequencing";
import SequenceCreate from "../components/Sequencing/SequenceCreate";
import SequenceDetailPage from "../components/Sequencing/SequenceDetailPage";
import WalkthoughVideosPage from "../components/WalkthroughVideos";
import TemplatePage from "../components/Templates/TemplatePage";
import WebFormList from "../components/WebForms/WebFormList";
import TaskSequenceList from "../components/Tasks/Sequence/TaskSequenceList";
import DashboardCustomScreen from "../components/DashboardCustom/DashboardCustomScreen";
import DashboardListPage from "../components/DashboardCustom/DashboardListPage";
import ResetPasswordPage from "../components/Profile/ResetPasswordPage";
import ZapierSettings from "../components/Profile/ZapierSettings";
import CustomizeAccountModalPage from "../components/CustomizeCreateModal/CustomizeAccountModalPage";
import ImportHubspotActivity from "../components/Import/ImportHubspotActivity";
import CarrierTest from "../components/Carrier/CarrierTest";
import CarrierCustom from "../components/Carrier/CustomFields/CarrierCustom";
import ImportPage from "../components/Import/ImportPage";
import ProtectedRoute from "./ProtectedRoute";
import ReportsCarrierSave from "../components/Reports/ReportsCarrierSave";

// Redux
import { connect } from "react-redux";
import { authUserSet, authTokenSet, companyOnboardingSet, setTab, setActiveTabIndex } from "../redux";
import ApolloLeadGenList from "../components/ApolloLeadGen/ApolloLeadGenList";
import ActivitiesMetricsReport from "../components/ActivitiesMetricsReport/ActivitiesMetricsReport";

const SalesdashRouter = (props) => {
  const history = useHistory()
  const url_string = window.location.href;
  const url = new URL(url_string);

  console.log("router data", props.totalTabs,url.searchParams.get("contact_id"), history)

  useEffect(() => {
    const contactId = url.searchParams.get("contact_id");
    const contactName = url.searchParams.get("contact_name");
    if (contactId) {
      props.setActiveTabIndex(props.totalTabs + 1);
      props.setTab({
        type: "contact",
        id: contactId,
        name: contactName || "contact",
        contactId,
      });
      history.push(`/active-tabs`);
    }
  }, []);
  return (
    <Switch>
      <Route path="/" component={Dashboard} exact={true} />
      <Route path="/accounts" component={AccountsList} />
      <Route path="/contacts" component={ContactsList} />
      <Route path="/reports" component={Reports} exact={true} />
      <Route path="/campaign" component={Campaign} exact={true} />
      <Route path="/reports/accounts/save" component={ReportsAccountSave} />
      <Route path="/reports/opportunities/save" component={ReportsDealSave} />
      <Route path="/reports/activities/save" component={ReportsActivitySave} />
      <Route path="/reports/contacts/save" component={ReportsContactSave} />
      <Route path="/reports/carriers/save" component={ReportsCarrierSave} />
      <Route path="/dashboard-custom" component={DashboardCustom} />
      <Route
        path="/dashboard-customization"
        component={DashboardCustomScreen}
      />
      <Route path="/dashboard-list" component={DashboardListPage} />
      <Route path="/analytics" component={AnalyticsComingSoon} exact={true} />
      <Route path="/apollo-lead-gen" component={ApolloLeadGenList} exact={true} />
      <Route path="/calendar" component={TasksCalendar} exact={true} />
      <Route path="/import" component={Import} exact={true} />
      <Route path="/import/deal" component={ImportDeal} exact={true} />
      <Route path="/import/:type" component={ImportPage} />
      <Route
        path="/import/hubspot/activity"
        component={ImportHubspotActivity}
        exact={true}
      />
      <Route path="/account-custom" component={AccountCustom} />
      <Route path="/contact-custom" component={ContactsCustom} />
      <Route path="/opportunity-custom" component={OpportunityCustom} />
      <ProtectedRoute
        path="/carrier-custom"
        component={CarrierCustom}
        isAllowed={props.authUser.permissions.includes("crm:all:customizer")}
      />
      <Route path="/me" exact component={ProfilePage} />
      <Route path="/me/auth" exact component={TwoFaPage} />
      <Route path="/me/reset-password" exact component={ResetPasswordPage} />
      <Route path="/me/zapier" exact component={ZapierSettings} />
      <Route path="/me/company" exact component={CompanySettings} />
      <Route path="/me/notification" exact component={NotificationSetting} />
      <Route path="/prices" component={index} />
      <Route path="/payment" component={Cards} />
      <Route path="/users" component={Users} exact={true} />
      <Route path="/users/import" component={ImportUser} />
      <Route path="/teams" component={Teams} exact={true} />
      <Route path="/teams/detail" component={TeamsDetailTable} />
      <Route path="/roles" component={Roles} />
      <Route path="/integration" component={Integration} />
      <Route path="/tasks" component={TasksList} exact={true} />
      <Route path="/activities-metrics-report" component={ActivitiesMetricsReport} exact={true} />
      <Route path="/tasks/sequence" component={TaskSequenceList} exact={true} />
      <Route path="/custom-modal" component={CustomizeAccountModalPage} />
      <Route path="/deals" component={DealsList} />
      <Route path="/activity" component={ActivityList} />
      <Route path="/dummy" component={DummyProfile} />
      <Route path="/sequencing" component={Sequencing} exact={true} />
      <Route path="/templates" component={TemplatePage} exact={true} />
      <Route
        path="/walk-through"
        component={WalkthoughVideosPage}
        exact={true}
      />
      <Route path="/sequencing/:id" component={SequenceDetailPage} />
      <Route
        path="/sequencing-create"
        component={SequenceCreate}
        exact={true}
      />
      <Route path="/webform/contact" exact={true} component={WebFormList} />
      <Route path="/webform/contact/:mode/:id" component={ContactForm} />
      <Route path="/webform/contact/:mode" component={ContactForm} />
      <Route path="/test-carrier" component={CarrierTest} />
    </Switch>
  );
};
const MSP = (state) => {
  return {
    authToken: state.login.authToken,
    authUser: state.login.authUser,
    companyOnboarding: state.login.companyOnboarding,
    totalTabs: state.tabsState.tabs.length,
  };
};

const MDP = (dispatch) => {
  return {
    authUserSet: (authUserData) => dispatch(authUserSet(authUserData)),
    authTokenSet: (authTokenData) => dispatch(authTokenSet(authTokenData)),
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
      setTab: (newTabData) => dispatch(setTab(newTabData)),
    setActiveTabIndex: (tabIndex) => dispatch(setActiveTabIndex(tabIndex)),
  };
};

export default connect(MSP, MDP)(SalesdashRouter);
