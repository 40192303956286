import axios from "axios";
import React, { useEffect, useState } from "react";
import ColumnGraph from "../../Charts/ColumnGraph";
// Redux stuff
import { connect } from "react-redux";

function DealsColumn({
  filter_rules,
  chartType,
  fromDashboard,
  setSegmentByDashboard,
  setYAxisDashboard,
  mode,
  segmentByDashboard,
  yAxisDashboard,
  viewDashboard,
  currency,
  fromReport,
  allDealCustomFields,
}) {
  const [segmentBy, setSegmentBy] = useState("stage");
  const [yAxis, setYAxis] = useState("no_of_deals");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState("");
  const [customFields, setCustomFields] = useState([]);
  const fetchCustomFields = () => {
    const customField = allDealCustomFields
      .map((group) => {
        const { fields, meta } = group;
        return (
          (meta === null || meta.visible_in_form === true) &&
          fields?.map((field) => field)
        );
      })
      .filter((m) => m !== false);
    const merged = customField.reduce(function (prev, next) {
      return prev.concat(next);
    }, []);
    const customFieldArray = merged
      .filter((m) => m !== false)
      ?.filter((field) => field.type !== "multi_select_dropdown");
    setCustomFields(customFieldArray);
  };
  useEffect(() => {
    fetchCustomFields();
  }, []);
  useEffect(() => {
    const getData = () => {
      let newValue = [];
      newValue = filter_rules.map((rule) => {
        let { attribute, operator, value } = rule;
        if (
          Array.isArray(value) &&
          (operator === "is_in" || operator === "is_not_in")
        )
          value = value.map((val) => val?.value);
        return {
          attribute,
          operator,
          value,
        };
      });
      setLoading(true);
      let url = `/charts/opportunity-chart`;
      axios({
        method: "POST",
        url,
        data: {
          chart: {
            type: "column",
            x_axis: segmentBy,
            y_axis: yAxis,
          },
          filter_rules: newValue,
        },
      })
        .then((res) => {
          setLoading(false);
          setData(res.data.opportunities);
          setRefresh(`${segmentBy}${yAxis}`);
        })
        .catch((err) => {
          setLoading(false);
        });
    };
    getData();
    fromDashboard && setSegmentByDashboard(segmentBy);
    fromDashboard && setYAxisDashboard(yAxis);
    fromReport && setRefresh(segmentBy + yAxis);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter_rules, segmentBy, yAxis]);
  useEffect(() => {
    mode === "edit" &&
      viewDashboard === chartType &&
      setSegmentBy(segmentByDashboard);
    mode === "edit" && viewDashboard === chartType && setYAxis(yAxisDashboard);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <label>X-axis</label>
      <select value={segmentBy} onChange={(e) => setSegmentBy(e.target.value)}>
        <option value="stage">Stage</option>
        <option value="opportunity_owner">Deal Owner</option>
        <option value="created_date">Created Date</option>
        <option value="close_date">Close Date</option>
        <option value="won_date">Won Date</option>
        <option value="duration">Duration</option>
        {customFields?.map((field) => (
          <option value={field.id}>{field.label}</option>
        ))}
      </select>
      <label>Y-axis</label>
      <select value={yAxis} onChange={(e) => setYAxis(e.target.value)}>
        <option value="no_of_deals">Number of deals</option>
        <option value="total_revenue">Revenue</option>
      </select>
      {loading ? (
        <div class="load-wrapp">
          <div class="load-3">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </div>
        </div>
      ) : (
        <ColumnGraph
          data={data}
          segmentBy={segmentBy}
          type="Deals"
          refresh={refresh}
          chartType={chartType}
          yAxis={yAxis}
          fromDashboard={fromDashboard}
          currency={currency}
        />
      )}
    </div>
  );
}

const MSP = (state) => {
  return {
    allDealCustomFields: state.allDealCustomFields.fields,
  };
};
const MDP = () => {
  return {};
};

export default connect(MSP, MDP)(DealsColumn);
