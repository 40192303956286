import React, { Component } from "react";
import ReactModal from "react-modal";
import "../../sass/ReactDraft.scss";
import CreatableSelect from "react-select/creatable";
import SendEmailIcon from "../../images/AccountEmailIcon.svg";
// import SequenceIcon from "../../images/sequenceicon.svg";
import axios from "axios";
import { debounce } from "lodash";
import { Hidden, Visible } from "react-grid-system";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import EmailSuccessModal from "./EmailSuccessModal";
import EmailFailureModal from "./EmailFailureModal";
import EditSignatureModal from "./EditSignatureModal";
import JoditDemo from "./JoditDemo";
import ReactTooltip from "react-tooltip";
import CreateTemplateModal from "./CreateTemplateModal";
import DropdownTemplate from "../Dropdown/DropdownTemplate";
import EditTemplateModal from "./EditTemplateModal";
import DeleteTemplateModal from "./DeleteTemplateModal";
import DropdownSchedule from "../Dropdown/DropdownSchedule";
import moment from "moment";
import AllSequenceModal from "../Sequencing/AllSequenceModal";
import SendSequenceModal from "../Sequencing/SendSequenceModal";
import BillingCreditModal from "../Reports/BillingCreditModal";
import BillingRedirectModal from "../Reports/BillingRedirectModal";
import PostMarkAppModal from "../Reports/PostMarkAppModal";
import PostMarkAppVerificationModal from "../Reports/PostMarkAppVerificationModal";
import PersonalizedVarsModal from "./PersonalizedVarsModal";
import EditSequenceModal from "../Sequencing/EditSequenceModal";
import SaveEmailModal from "./SaveEmailModal";
import JoditEditor from "jodit-react";

ReactModal.defaultStyles.overlay.background = "transparent";

const config = {
  height: "auto",
  minHeight: "20px",
  width: "inherit",
  minWidth: "20px",
  toolbar: false,
  enableDragAndDropFileToEditor: true,
  askBeforePasteFromWord: false,
  askBeforePasteHTML: false,
  link: {
    modeClassName: false,
  },
  showXPathInStatusbar: false,
  showCharsCounter: false,
  showWordsCounter: false,
  toolbarAdaptive: false,
  toolbarSticky: true,
  enter: "BR",
  placeholder: "Enter Subject",
  zIndex: 2147483640,
  addNewLine: false,
  allowResizeY: false,
};
class SendEmailModal extends Component {
  state = {
    showModal: true,
    uploadedImages: [],
    CC: false,
    BCC: false,
    fullScreen: false,
    subject: "",
    contentState: "",
    toEmail: [],
    toEmailFrontEnd: [],
    ccEmail: [],
    ccEmailFrontEnd: [],
    bccEmail: [],
    bccEmailFrontEnd: [],
    errorMessage: false,
    windowWidth: window.innerWidth,
    showSuccessModal: false,
    showFailureModal: false,
    showSendSequenceModal: false,
    selectedSequence: {},
    showEditSignature: false,
    showTemplateModal: false,
    showTemplateEditModal: false,
    showTemplateDeleteModal: false,
    emailSignature: "",
    isLoading: false,
    content: "",
    selectedFile: "",
    attachments: [],
    fileNames: [],
    allTemplates: [],
    templateData: {},
    attachmentLoader: false,
    scheduleDate: "",
    scheduleTime: null,
    scheduleErrorMessage: false,
    scheduleIsLoading: false,
    successMessage: "sent",
    minimize: false,
    showCreditsModal: false,
    showRedirectModal: false,
    showPostMarkModal: false,
    showEditSequenceModal: false,
    showVerificationModal: false,
    subscriptionStatus: null,
    signatureId: null,
    signatureVerified: false,
    emailCredits: 0,
    total: 0,
    showPersonalizedVarsModal: false,
    personalizedVariablesArray: [],
    templateId: null,
    uploaderErrorMessage: false,
  };
  fileInput = React.createRef();
  triggerInputFile = () => this.fileInput.current.click();
  updateSubject = (value) => {
    this.setState({ subject: value });
  };
  jodit;
  setRef = (jodit) => (this.jodit = jodit);
  setUploaderErrorMessage = () => {
    this.setState({
      uploaderErrorMessage: true,
    });
  };

  getSingleTemplateData = (
    id,
    label,
    subject,
    content,
    personalizeVars,
    isPrivate = true
  ) => {
    this.setState({
      templateData: {
        id,
        label,
        subject,
        content,
        personalizeVars,
        isPrivate,
      },
    });
  };

  dateChangeHandler = (date) => {
    const offsetDate =
      date === null || date === ""
        ? new Date()
        : date.getTimezoneOffset() < 0
        ? new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        : new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    this.setState({
      scheduleDate: offsetDate,
    });
  };
  timeChangeHandler = (date) => {
    this.setState({
      scheduleTime: date,
    });
  };

  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };
  handleInputChange = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
    });
    this.submitAttachment(event.target.files[0]);
  };
  onInputClick = (event) => {
    event.target.value = "";
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };
  handleOpenSuccessModal = () => {
    this.setState({
      showSuccessModal: true,
    });
  };
  handleCloseSuccessModal = () => {
    this.setState({
      showSuccessModal: false,
    });
    this.props.handleCloseModal();
  };
  handleOpenEditSequenceModal = () => {
    this.setState(
      {
        showEditSequenceModal: true,
        showSendSequenceModal: false,
      },
      () => this.props.handleCloseAllSequenceModal()
    );
  };
  handleCloseEditSequenceModal = () => {
    this.setState(
      {
        showEditSequenceModal: false,
        showSendSequenceModal: false,
      },
      () => this.props.handleCloseAllSequenceModal()
    );
  };
  handleOpenSignatureModal = () => {
    this.setState({
      showEditSignature: true,
    });
  };
  handleCloseSignatureModal = () => {
    this.setState({
      showEditSignature: false,
    });
  };
  handleOpenPersonalizedVarsModal = () => {
    this.setState({
      showPersonalizedVarsModal: true,
    });
  };
  handleClosePersonalizedVarsModal = () => {
    this.setState({
      showPersonalizedVarsModal: false,
    });
  };
  handleCreditsOpenModal = () => {
    this.setState({
      showCreditsModal: true,
    });
  };
  handleCreditsCloseModal = () => {
    this.setState({
      showCreditsModal: false,
    });
  };
  handleRedirectOpenModal = () => {
    this.setState({
      showRedirectModal: true,
    });
  };
  handleRedirectCloseModal = () => {
    this.setState({
      showRedirectModal: false,
    });
  };
  handlePostMarkOpenModal = () => {
    this.setState({
      showPostMarkModal: true,
    });
  };
  handlePostMarkCloseModal = () => {
    this.setState({
      showPostMarkModal: false,
    });
  };
  handleVerificationOpenModal = () => {
    this.setState({
      showVerificationModal: true,
    });
  };
  handleVerificationCloseModal = () => {
    this.setState({
      showVerificationModal: false,
    });
  };
  handleOpenSendSequenceModal = () => {
    this.setState({
      showSendSequenceModal: true,
    });
  };
  handleCloseSendSequenceModal = () => {
    this.setState({
      showSendSequenceModal: false,
    });
  };
  handleOpenTemplateModal = () => {
    this.setState({
      showTemplateModal: true,
    });
  };
  handleCloseTemplateModal = () => {
    this.setState({
      showTemplateModal: false,
    });
  };
  handleOpenTemplateEditModal = () => {
    this.setState({
      showTemplateEditModal: true,
    });
  };
  handleCloseTemplateEditModal = () => {
    this.setState({
      showTemplateEditModal: false,
    });
  };
  handleOpenTemplateDeleteModal = () => {
    this.setState({
      showTemplateDeleteModal: true,
    });
  };
  handleCloseTemplateDeleteModal = () => {
    this.setState({
      showTemplateDeleteModal: false,
    });
  };
  handleOpenFailureModal = () => {
    this.setState({
      showFailureModal: true,
    });
  };
  handleCloseFailureModal = () => {
    this.setState({
      showFailureModal: false,
    });
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  updateContent = (value) => {
    this.setState({ content: value });
  };

  setSequenceValue = (value) => {
    axios({
      method: "GET",
      url: `/sequences/${value}?${this.props.type}=${this.props.idSequence}`,
    }).then((res) => {
      this.setState(
        {
          selectedSequence: res.data.sequence,
        },
        () => {
          this.handleOpenSendSequenceModal();
          this.props.handleCloseAllSequenceModal();
        }
      );
    });
  };

  changeHandler = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
    if (this.state.windowWidth < 800) {
      this.setState({ fullScreen: true });
    }
  };
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    if (this.state.windowWidth < 800) {
      this.setState({ fullScreen: true });
    }
    this.setState({
      subject: this.props.subjectReplied
        ? `Re: ${this.props.subjectReplied}`
        : "",
      // toEmail: this.props.replyToEmail ? this.props.replyToEmail : [],
      // toEmailFrontEnd: this.props.replyToEmail
      //   ? this.props.replyToEmail?.map((e) => {
      //       return { label: e, value: e };
      //     })
      //   : [],
    });
    if (this.props.showOpportunityModal) {
      this.getSignature();
      this.getAllTemplates();
      this.checkUserProfile();
      this.checkSubscription();
    }
  }
  getSignature = () => {
    axios({
      method: "GET",
      url: `/users/email-signature`,
    }).then((res) => {
      if (res.data.email_signature !== null) {
        this.setState({
          content:
            res.data.email_signature === null
              ? ""
              : `<br/><br/>` + res.data.email_signature,
          emailSignature: res.data.email_signature,
        });
      }
    });
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.showOpportunityModal !== this.props.showOpportunityModal) {
      this.setState({
        subject: this.props.subjectReplied
          ? `Re: ${this.props.subjectReplied}`
          : "",
        toEmail: this.props.replyToEmail
          ? Array.isArray(this.props.replyToEmail)
            ? this.props.replyToEmail
            : [this.props.replyToEmail]
          : [],
        toEmailFrontEnd: this.props.replyToEmail
          ? Array.isArray(this.props.replyToEmail)
            ? this.props.replyToEmail?.map((e) => {
                return { label: e, value: e };
              })
            : [this.props.replyToEmail]?.map((e) => {
                return { label: e, value: e };
              })
          : [],
      });
      this.getSignature();
      this.getAllTemplates();
      this.checkUserProfile();
      this.checkSubscription();
    }
    if (
      this.state.uploaderErrorMessage &&
      this.state.uploaderErrorMessage !== prevState.uploaderErrorMessage
    ) {
      setTimeout(() => {
        this.setState({
          uploaderErrorMessage: false,
        });
      }, 5000);
    }
  }
  submitAttachment = (selectedFile) => {
    const { accountId, contactId, opportunityId, carrierId } = this.props;
    const formData = new FormData();
    formData.append("attachment", selectedFile);
    accountId && formData.append("account_id", accountId);
    carrierId && formData.append("carrier_id", carrierId);
    contactId && formData.append("contact_id", contactId);
    opportunityId && formData.append("opportunity_id", opportunityId);
    this.setState({ attachmentLoader: true });
    axios({
      method: "POST",
      url: `/attachments`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    })
      .then((res) => {
        this.setState({ attachmentLoader: false });
        this.setState((prevState) => ({
          attachments: [...prevState.attachments, res.data.s3_url],
          fileNames: [
            ...prevState.fileNames,
            { name: selectedFile.name, url: res.data.s3_url },
          ],
          selectedFile: "",
        }));
      })
      .catch((err) => this.setState({ attachmentLoader: false }));
  };
  removeAttachment = (url) => {
    this.setState({
      attachments: this.state.attachments?.filter((attach) => attach !== url),
      fileNames: this.state.fileNames?.filter((file) => file.url !== url),
    });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  toEmailChangeHandler = (selected) => {
    let selectedArray =
      selected
        ?.reduce((acc, val) => {
          return [acc, val.value];
        }, [])
        .flat(Infinity) ?? [];

    if (selected) {
      selected.map((s) => {
        const { value } = s;
        const validator =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (validator.test(value)) {
          this.setState((prevState) => ({
            toEmail: selectedArray.filter((s) => validator.test(s)),
            toEmailFrontEnd: selected.filter((s) => validator.test(s.value)),
          }));
        } else {
          NotificationManager.error("Invalid email");
        }
        return s;
      });
    } else {
      this.setState((prevState) => ({
        toEmail: selectedArray,
        toEmailFrontEnd: selected,
      }));
    }
  };

  ccEmailChangeHandler = (selected) => {
    let selectedArray =
      selected
        ?.reduce((acc, val) => {
          return [acc, val.value];
        }, [])
        .flat(Infinity) ?? [];

    if (selected) {
      selected.map((s) => {
        const { value } = s;
        const validator =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (validator.test(value)) {
          this.setState((prevState) => ({
            ccEmail: selectedArray.filter((s) => validator.test(s)),
            ccEmailFrontEnd: selected.filter((s) => validator.test(s.value)),
          }));
        } else {
          NotificationManager.error("Invalid email");
        }
        return s;
      });
    } else {
      this.setState((prevState) => ({
        ccEmail: selectedArray,
        ccEmailFrontEnd: selected,
      }));
    }
  };

  bccEmailChangeHandler = (selected) => {
    let selectedArray =
      selected
        ?.reduce((acc, val) => {
          return [acc, val.value];
        }, [])
        .flat(Infinity) ?? [];

    if (selected) {
      selected.map((s) => {
        const { value } = s;
        const validator =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (validator.test(value)) {
          this.setState((prevState) => ({
            bccEmail: selectedArray.filter((s) => validator.test(s)),
            bccEmailFrontEnd: selected.filter((s) => validator.test(s.value)),
          }));
        } else {
          NotificationManager.error("Invalid email");
        }
        return s;
      });
    } else {
      this.setState((prevState) => ({
        bccEmail: selectedArray,
        bccEmailFrontEnd: selected,
      }));
    }
  };

  getAllTemplates = debounce((query) => {
    let url = `/emails/templates`;
    if (query) {
      url += `?name=${encodeURIComponent(query)}`;
    }
    axios({
      method: "GET",
      url,
    }).then((res) => this.setState({ allTemplates: res.data.templates }));
  }, 500);

  readTemplateValues = (tempId) => {
    let url = `/emails/templates/${tempId}`;
    if (this.props.type === "account_id") {
      url += `?account_id=${this.props.accountId}`;
    } else if (this.props.type === "opportunity_id") {
      url += `?opportunity_id=${this.props.opportunityId}`;
    } else if (this.props.type === "carrier_id") {
      url += `?carrier_id=${this.props.carrierId}`;
    } else {
      url += `?contact_id=${this.props.contactId}`;
    }
    axios({
      method: "GET",
      url,
    }).then((res) => {
      const personalizeVarsArray = res.data.template.personalize_vars?.map(
        (vars) => {
          let value = vars.value;
          if (vars.value?.startsWith(";")) {
            value = vars.value
              ?.split(";")
              ?.filter((a) => a)
              ?.join(", ");
          }
          return value;
        }
      );
      console.log("personalizeVarsArray", personalizeVarsArray);
      let finalContent = res.data.template.body;
      let finalSubject = res.data.template.subject;
      if (personalizeVarsArray.includes(null)) {
        this.setState(
          {
            personalizedVariablesArray: res.data.template.personalize_vars?.map(
              (vars) => {
                let value = vars.value;
                if (vars.value?.startsWith(";")) {
                  value = vars.value
                    ?.split(";")
                    ?.filter((a) => a)
                    ?.join(", ");
                }
                return { ...vars, value };
              }
            ),
            templateId: tempId,
          },
          () => this.handleOpenPersonalizedVarsModal()
        );
      } else {
        for (let i = 0; i < res.data.template.personalize_vars.length; i++) {
          let value = res.data.template.personalize_vars[i].value;
          if (res.data.template.personalize_vars[i].value?.startsWith(";")) {
            value = res.data.template.personalize_vars[i].value
              ?.split(";")
              ?.filter((a) => a)
              ?.join(", ");
          }
          finalContent = finalContent.includes(
            `{{ ${res.data.template.personalize_vars[i].key} }}`
          )
            ? finalContent.replaceAll(
                `{{ ${res.data.template.personalize_vars[i].key} }}`,
                value
              )
            : finalContent;
          finalSubject = finalSubject.includes(
            `{{ ${res.data.template.personalize_vars[i].key} }}`
          )
            ? finalSubject.replaceAll(
                `{{ ${res.data.template.personalize_vars[i].key} }}`,
                value
              )
            : finalSubject;
        }
        this.setState({
          subject: finalSubject,
          templateId: tempId,
          content:
            this.state.emailSignature === null
              ? finalContent
              : finalContent + `<br/><br/>` + this.state.emailSignature,
        });
      }
    });
  };
  readTemplateVarsValues = (tempId, personalizedVariables) => {
    let url = `/emails/templates/${tempId}`;
    if (this.props.type === "account_id") {
      url += `?account_id=${this.props.accountId}`;
    } else if (this.props.type === "opportunity_id") {
      url += `?opportunity_id=${this.props.opportunityId}`;
    } else if (this.props.type === "carrier_id") {
      url += `?carrier_id=${this.props.carrierId}`;
    } else {
      url += `?contact_id=${this.props.contactId}`;
    }
    axios({
      method: "GET",
      url,
    }).then((res) => {
      this.handleClosePersonalizedVarsModal();
      let finalContent = res.data.template.body;
      let finalSubject = res.data.template.subject;
      for (let i = 0; i < personalizedVariables.length; i++) {
        let value = personalizedVariables[i].value;
        if (personalizedVariables[i].value?.startsWith(";")) {
          value = personalizedVariables[i].value
            ?.split(";")
            ?.filter((a) => a)
            ?.join(", ");
        }
        finalContent = finalContent.includes(
          `{{ ${personalizedVariables[i].key} }}`
        )
          ? finalContent.replaceAll(
              `{{ ${personalizedVariables[i].key} }}`,
              value
            )
          : finalContent;
        finalSubject = finalSubject.includes(
          `{{ ${personalizedVariables[i].key} }}`
        )
          ? finalSubject.replaceAll(
              `{{ ${personalizedVariables[i].key} }}`,
              value
            )
          : finalSubject;
      }
      this.setState({
        subject: finalSubject,
        content:
          this.state.emailSignature === null
            ? finalContent
            : finalContent + `<br/><br/>` + this.state.emailSignature,
      });
    });
  };

  submitHandler = (schedule = false, e) => {
    e.preventDefault();
    const {
      subject,
      toEmail,
      ccEmail,
      bccEmail,
      content,
      fileNames,
      scheduleDate,
      scheduleTime,
    } = this.state;
    const data = this.state;
    if (!data.subject || !data.toEmail) {
      this.setState({
        errorMessage: true,
        isLoading: false,
        scheduleIsLoading: false,
      });
      return false;
    }
    if (schedule === true && (!scheduleDate || !scheduleTime)) {
      this.setState({
        scheduleErrorMessage: true,
        isLoading: false,
        scheduleIsLoading: false,
      });
      return false;
    }
    if (
      this.props.integration === null &&
      (this.state.emailCredits === null || this.state.emailCredits === 0)
    ) {
      return this.handleCreditsOpenModal();
    }
    const newDate =
      this.state.scheduleDate &&
      moment(this.state.scheduleDate).format("YYYY-MM-DD");
    const newTime =
      this.state.scheduleTime &&
      moment(this.state.scheduleTime).format("HH:mm:ss");
    const myTime = moment(`${newDate} ${newTime}`).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    const sendTime = new Date(new Date(myTime).toUTCString().substr(0, 25));
    const sentTimeInMoment = moment(sendTime).format("YYYY-MM-DD HH:mm:ss");
    this.setState({ isLoading: true });
    schedule === true && this.setState({ scheduleIsLoading: true });
    axios({
      method: "POST",
      url: `/emails`,
      data: {
        ...(this.props.accountId && { account_id: this.props.accountId }),
        ...(this.props.carrierId && { carrier_id: this.props.carrierId }),
        ...(this.props.contactId && { contact_id: this.props.contactId }),
        ...(this.props.opportunityId && {
          opportunity_id: this.props.opportunityId,
        }),
        category_id: 2,
        is_reply: this.props.isReply === true ? true : false,
        ...(this.props.isReply === true && {
          activity_id: this.props.activityId,
          parent_id: parseInt(this.props.parentId),
        }),
        ...(schedule === true && {
          schedule: true,
          send_at: sentTimeInMoment,
        }),
        ...(schedule === false && {
          schedule: false,
        }),
        ...(this.state.templateId && {
          template_id: this.state.templateId,
        }),
        email: {
          to: toEmail,
          cc: ccEmail,
          bcc: bccEmail,
          subject: subject,
          body: content,
          attachments: fileNames,
        },
      },
    })
      .then((res) => {
        const successMessage = schedule === false ? "sent" : "scheduled";
        this.setState({ successMessage }, () => this.handleOpenSuccessModal());
        this.props.loadActivities(1, "refetch");
        this.props.taskComplete && this.props.taskSubmitHandler(e);
        this.setState({
          isLoading: false,
          toEmail: [],
          toEmailFrontEnd: [],
          ccEmail: [],
          ccEmailFrontEnd: [],
          bccEmail: [],
          bccEmailFrontEnd: [],
          errorMessage: false,
          subject: "",
          scheduleDate: "",
          scheduleTime: null,
          scheduleErrorMessage: false,
          scheduleIsLoading: false,
          templateId: null,
        });
      })
      .catch((error) => {
        this.handleOpenFailureModal();
        this.setState({ isLoading: false, scheduleIsLoading: false });
      });
  };
  checkUserProfile = () => {
    axios({
      method: "GET",
      url: `/users/profile`,
    }).then((res) => {
      this.setState({
        signatureVerified: res.data.postmark_signature_verified,
        signatureId: res.data.postmark_signature_id,
        emailCredits: res.data.postmark_email_balance,
      });
    });
  };
  checkVerification = () => {
    axios({
      method: "GET",
      url: `/users/profile`,
    }).then((res) => {
      this.verifySignature(res.data.postmark_signature_verified);
      this.setState({
        signatureVerified: res.data.postmark_signature_verified,
        signatureId: res.data.postmark_signature_id,
        emailCredits: res.data.postmark_email_balance,
      });
    });
  };
  verifySignature = (signatureVerified) => {
    if (!signatureVerified) {
      axios({
        method: "PATCH",
        url: `/users/verify-postmark-signature`,
        data: {},
      })
        .then((res) => {
          this.handleVerificationCloseModal();
          this.checkUserProfile();
          this.handleCreditsOpenModal();
        })
        .catch((err) => {
          NotificationManager.error("Email not verified.");
        });
    }
  };
  checkSubscription = () => {
    axios({
      method: "GET",
      url: `/company/${this.props.companyId}/subscription-status`,
    }).then((res) => {
      this.setState({
        subscriptionStatus: res.data.subscription.status,
      });
    });
  };
  emailDecision = () => {
    if (this.state.signatureId === null) {
      this.handlePostMarkOpenModal();
    } else if (!this.state.signatureVerified) {
      this.handleVerificationOpenModal();
    } else if (
      this.state.emailCredits === null ||
      10 > this.state.emailCredits
    ) {
      this.handleCreditsOpenModal();
    } else {
      this.props.handleOpenAllSequenceModal();
    }
  };
  replyEmailDecision = (schedule, e) => {
    if (this.state.signatureId === null) {
      this.handlePostMarkOpenModal();
    } else if (!this.state.signatureVerified) {
      this.handleVerificationOpenModal();
    } else if (
      this.state.emailCredits === null ||
      10 > this.state.emailCredits
    ) {
      this.handleCreditsOpenModal();
    } else {
      this.submitHandler(schedule, e);
    }
  };

  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    const colourStyles = {
      control: (styles) => ({
        ...styles,
        backgroundColor: "white",
        width: "100%",
        outline: "none",
        minHeight: "30px",
        padding: "5px auto",
        transform: "translateY(-10px)",
        border: "none",
      }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isDisabled
            ? null
            : isSelected
            ? data.color
            : isFocused
            ? "#C0EAD8"
            : null,
          cursor: isDisabled ? "not-allowed" : "default",

          ":active": {
            ...styles[":active"],
            backgroundColor:
              !isDisabled && (isSelected ? data.color : "#C0EAD8"),
          },
        };
      },
      multiValue: (styles, { data }) => {
        return {
          ...styles,
          backgroundColor: "#C0EAD8",
        };
      },
      multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: "black",
        fontSize: "13px",
      }),
    };
    const emailValue = [
      {
        label: `${this.props.recordName} <${this.props.email}>`,
        value: this.props.email,
      },
    ];
    const emailOptionArray =
      this.props.contactEmailsArray === undefined
        ? emailValue
        : emailValue.concat(
            this.props.contactEmailsArray?.map((email) => {
              return {
                label: `${email.name} <${email.email}>`,
                value: email.email,
              };
            })
          );
    const allUsersArray = this.props.allUsers?.map((user) => ({
      label: user.email,
      value: user.email,
    }));
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          // onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div
            className="modalEmail"
            style={this.state.minimize ? { height: "10vh", top: "73%" } : {}}
          >
            <div
              className="modal__content"
              style={
                this.state.minimize
                  ? {
                      height: "10vh",
                      width: "55vw",
                      top: "83%",
                      left: "35%",
                      border: "none",
                      boxShadow: "4px 4px 4px black,-4px -4px 4px black",
                    }
                  : this.state.fullScreen
                  ? this.state.windowWidth < 600
                    ? {
                        height: "84vh",
                        width: "87vw",
                        top: "5%",
                        left: "2%",
                        border: "none",
                        boxShadow: "4px 4px 4px black,-4px -4px 4px black",
                      }
                    : {
                        height: "84vh",
                        width: "90vw",
                        top: "7%",
                        left: "2%",
                        border: "none",
                        boxShadow: "4px 4px 4px black,-4px -4px 4px black",
                      }
                  : this.state.BCC || this.state.CC
                  ? {
                      height: "76vh",
                      width: "55vw",
                      top: "18%",
                      left: "35%",
                      border: "none",
                      boxShadow: "4px 4px 4px black,-4px -4px 4px black",
                    }
                  : {
                      height: "73vh",
                      width: "55vw",
                      top: "20%",
                      left: "35%",
                      border: "none",
                      boxShadow: "4px 4px 4px black,-4px -4px 4px black",
                    }
              }
            >
              <div className="modal__header">
                <span>
                  <div
                    className="customerInfo-icon"
                    style={{
                      background: "transparent",
                      transform: "translateY(-10px)",
                      height: "30px",
                      width: "30px",
                    }}
                  >
                    <img
                      src={SendEmailIcon}
                      alt="Icon"
                      width="20px"
                      style={{ transform: "translateY(11px)" }}
                    />
                  </div>
                </span>
                <div className="modal__head" style={{ marginLeft: "8px" }}>
                  <h2 className="modal__heading" style={{ fontSize: "18px" }}>
                    Compose Email
                  </h2>
                </div>
                <NotificationContainer />
                <button
                  className="modal__restore"
                  type="button"
                  onClick={() =>
                    this.setState({ minimize: !this.state.minimize })
                  }
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                    right: "11.5%",
                  }}
                >
                  <i className="fa fa-window-minimize" aria-hidden="true"></i>
                </button>
                {this.state.windowWidth > 800 && (
                  <button
                    onClick={() =>
                      this.setState({ fullScreen: !this.state.fullScreen })
                    }
                    className="modal__restore"
                    style={{
                      background: "transparent",
                      border: "none",
                      outline: "none",
                      cursor: "pointer",
                      right: "8%",
                    }}
                  >
                    {this.state.fullScreen ? (
                      <i
                        className="fa fa-window-restore"
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i
                        className="fa fa-window-maximize"
                        aria-hidden="true"
                      ></i>
                    )}
                  </button>
                )}
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  <i className="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <EmailSuccessModal
                showOpportunityModal={this.state.showSuccessModal}
                setShowOpportunityModal={this.handleOpenSuccessModal}
                handleCloseModal={this.handleCloseSuccessModal}
                message={this.state.successMessage}
              />
              <EmailFailureModal
                showOpportunityModal={this.state.showFailureModal}
                setShowOpportunityModal={this.handleOpenFailureModal}
                handleCloseModal={this.handleCloseFailureModal}
              />
              <EditSignatureModal
                showOpportunityModal={this.state.showEditSignature}
                setShowOpportunityModal={this.handleOpenSignatureModal}
                handleCloseModal={this.handleCloseSignatureModal}
                getSignature={this.getSignature}
                accountId={this.props.accountId}
                carrierId={this.props.carrierId}
                opportunityId={this.props.opportunityId}
                contactId={this.props.contactId}
              />
              <CreateTemplateModal
                showOpportunityModal={this.state.showTemplateModal}
                setShowOpportunityModal={this.handleOpenTemplateModal}
                handleCloseModal={this.handleCloseTemplateModal}
                accountId={this.props.accountId}
                carrierId={this.props.carrierId}
                opportunityId={this.props.opportunityId}
                contactId={this.props.contactId}
                getAllTemplates={this.getAllTemplates}
              />
              <EditTemplateModal
                showOpportunityModal={this.state.showTemplateEditModal}
                setShowOpportunityModal={this.handleOpenTemplateEditModal}
                handleCloseModal={this.handleCloseTemplateEditModal}
                accountId={this.props.accountId}
                carrierId={this.props.carrierId}
                opportunityId={this.props.opportunityId}
                contactId={this.props.contactId}
                getAllTemplates={this.getAllTemplates}
                templateData={this.state?.templateData}
              />
              <DeleteTemplateModal
                showOpportunityModal={this.state.showTemplateDeleteModal}
                setShowOpportunityModal={this.handleOpenTemplateDeleteModal}
                handleCloseModal={this.handleCloseTemplateDeleteModal}
                getAllTemplates={this.getAllTemplates}
                templateData={this.state?.templateData}
              />
              <AllSequenceModal
                showOpportunityModal={this.props.showAllSequenceModal}
                setShowOpportunityModal={this.props.handleOpenAllSequenceModal}
                handleCloseModal={this.props.handleCloseAllSequenceModal}
                setSequenceValue={this.setSequenceValue}
                handleOpenEditSequenceModal={this.handleOpenEditSequenceModal}
              />
              <SaveEmailModal
                showModal={this.props.showSaveEmailModal}
                handleCloseModal={this.props.handleCloseSaveEmailModal}
                email={this.props.email}
                changeHandler={this.props.recordChangeHandler}
                modalHeight={45}
                modalTop={15}
                loading={this.props.recorderLoading}
                submitHandler={this.props.recordSubmitHandler}
                error={this.props.emailValidationError}
              />
              <SendSequenceModal
                showOpportunityModal={this.state.showSendSequenceModal}
                setShowOpportunityModal={this.handleOpenSendSequenceModal}
                handleCloseModal={this.handleCloseSendSequenceModal}
                selectedSequence={this.state.selectedSequence}
                toEmail={this.props.replyToEmail}
                accountId={this.props.accountId}
                carrierId={this.props.carrierId}
                opportunityId={this.props.opportunityId}
                contactId={this.props.contactId}
                fromEmail={this.props.fromEmail}
                handleCloseEmailModal={this.props.handleCloseModal}
                emailType={this.props.type}
                loadActivities={this.props.loadActivities}
                emailSignature={this.state.emailSignature}
              />
              <EditSequenceModal
                showOpportunityModal={this.state.showEditSequenceModal}
                setShowOpportunityModal={this.handleOpenEditSequenceModal}
                handleCloseModal={this.handleCloseEditSequenceModal}
                selectedSequence={this.state.selectedSequence}
              />
              <BillingCreditModal
                showOpportunityModal={this.state.showCreditsModal}
                setShowOpportunityModal={this.handleCreditsOpenModal}
                handleCloseModal={this.handleCreditsCloseModal}
                handleEmailOpenModal={this.props.handleOpenAllSequenceModal}
                checkUserProfile={this.checkUserProfile}
              />
              <BillingRedirectModal
                showOpportunityModal={this.state.showRedirectModal}
                setShowOpportunityModal={this.handleRedirectOpenModal}
                handleCloseModal={this.handleRedirectCloseModal}
              />
              <PostMarkAppModal
                showOpportunityModal={this.state.showPostMarkModal}
                setShowOpportunityModal={this.handlePostMarkOpenModal}
                handleCloseModal={this.handlePostMarkCloseModal}
                handleVerificationOpenModal={this.handleVerificationOpenModal}
                checkUserProfile={this.checkUserProfile}
              />
              <PostMarkAppVerificationModal
                showOpportunityModal={this.state.showVerificationModal}
                setShowOpportunityModal={this.handleVerificationOpenModal}
                handleCloseModal={this.handleVerificationCloseModal}
                checkUserProfile={this.checkUserProfile}
                signatureVerified={this.state.signatureVerified}
                handleEmailOpenModal={this.props.handleOpenAllSequenceModal}
                checkVerification={this.checkVerification}
              />
              <PersonalizedVarsModal
                showOpportunityModal={this.state.showPersonalizedVarsModal}
                setShowOpportunityModal={this.handleOpenPersonalizedVarsModal}
                handleCloseModal={this.handleClosePersonalizedVarsModal}
                readTemplateVarsValues={this.readTemplateVarsValues}
                templateId={this.state.templateId}
                personalizedVariablesArray={
                  this.state.personalizedVariablesArray
                }
              />
              <form
                onSubmit={(e) =>
                  this.props.replyToEmail &&
                  this.props.replySentBy === "postmark"
                    ? this.replyEmailDecision(false, e)
                    : this.submitHandler(false, e)
                }
              >
                <div
                  className="card-email-modal"
                  style={{ marginBottom: "10px" }}
                >
                  <label
                    style={{
                      display: "inline-block",
                      transform: "translateY(-9px)",
                      marginRight: "5px",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    To
                  </label>
                  <div style={{ display: "inline-block", width: "96%" }}>
                    <CreatableSelect
                      isMulti
                      defaultValue={{
                        value: this.props.email,
                        label: this.props.email,
                      }}
                      value={this.state.toEmailFrontEnd}
                      onChange={(selected) =>
                        this.toEmailChangeHandler(selected)
                      }
                      options={emailOptionArray}
                      styles={colourStyles}
                      placeholder="Enter or choose an email address"
                      formatCreateLabel={(inputText) => `Add ${inputText}`}
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                        ClearIndicator: () => null,
                      }}
                    />
                  </div>
                  <div
                    style={{
                      color: "#F36363",
                      fontSize: "12px",
                      marginBottom: "5px",
                    }}
                  >
                    {this.state.errorMessage === true &&
                    (this.state.toEmailFrontEnd === null ||
                      this.state.toEmailFrontEnd === "" ||
                      !(
                        Array.isArray(this.state.toEmailFrontEnd) &&
                        this.state.toEmailFrontEnd.length
                      ))
                      ? "Please enter recipient email"
                      : null}
                  </div>
                </div>
                {this.state.CC && (
                  <div
                    className="card-email-modal"
                    style={{ marginBottom: "10px" }}
                  >
                    <label
                      style={{
                        display: "inline-block",
                        transform: "translateY(-9px)",
                        marginRight: "5px",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Cc
                    </label>
                    <div style={{ display: "inline-block", width: "96%" }}>
                      <CreatableSelect
                        isMulti
                        value={this.state.ccEmailFrontEnd}
                        onChange={(selected) =>
                          this.ccEmailChangeHandler(selected)
                        }
                        options={allUsersArray}
                        styles={colourStyles}
                        placeholder="Enter or choose an email address"
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                          ClearIndicator: () => null,
                        }}
                      />
                    </div>
                  </div>
                )}
                {this.state.BCC && (
                  <div className="card-email-modal">
                    <label
                      style={{
                        display: "inline-block",
                        transform: "translateY(-9px)",
                        marginRight: "5px",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      Bcc
                    </label>
                    <div style={{ display: "inline-block", width: "96%" }}>
                      <CreatableSelect
                        isMulti
                        value={this.state.bccEmailFrontEnd}
                        onChange={(selected) =>
                          this.bccEmailChangeHandler(selected)
                        }
                        options={allUsersArray}
                        styles={colourStyles}
                        placeholder="Enter or choose an email address"
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                          ClearIndicator: () => null,
                        }}
                      />
                    </div>
                  </div>
                )}
                {this.state.CC === false && (
                  <button
                    type="button"
                    onClick={() => this.setState({ CC: true })}
                    style={{
                      background: "transparent",
                      border: "none",
                      outline: "none",
                      cursor: "pointer",
                      color: "#61C99D",
                      textDecoration: "underline",
                    }}
                  >
                    Cc
                  </button>
                )}
                {this.state.BCC === false && (
                  <button
                    type="button"
                    onClick={() => this.setState({ BCC: true })}
                    style={{
                      background: "transparent",
                      border: "none",
                      outline: "none",
                      cursor: "pointer",
                      color: "#61C99D",
                      textDecoration: "underline",
                    }}
                  >
                    Bcc
                  </button>
                )}
                <div
                  className="card-email-modal"
                  style={{
                    margin: "10px 0",
                    padding: "4px 10px",
                    display: "flex",
                  }}
                >
                  <label
                    style={{
                      fontSize: "14px",
                      display: "inline-block",
                      marginRight: "5px",
                      fontWeight: "500",
                      transform: "translateY(8px)",
                    }}
                  >
                    Subject
                  </label>
                  <div style={{ width: "100%", fontSize: "14px" }}>
                    <JoditEditor
                      value={this.state.subject}
                      config={config}
                      editorRef={this.setRef}
                      onChange={(content) => {
                        this.updateSubject(content);
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  {this.state.errorMessage === true &&
                  (this.state.subject === null || this.state.subject === "")
                    ? "Subject is required"
                    : null}
                </div>
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "14px",
                    marginBottom: "5px",
                  }}
                >
                  {this.state.uploaderErrorMessage === true
                    ? "Unable to paste image. Please save your image and upload it to the email body."
                    : null}
                </div>
                <div
                  className="card-email-modal"
                  style={
                    this.state.fullScreen
                      ? { height: "45vh", maxHeight: "45vh", minHeight: "45vh" }
                      : {
                          height: "38vh",
                          maxHeight: "38vh",
                          minHeight: "36.2vh",
                          padding: "4px 10px 17px",
                        }
                  }
                >
                  <JoditDemo
                    content={this.state.content}
                    updateContent={this.updateContent}
                    triggerInputFile={this.triggerInputFile}
                    accountId={this.props.accountId}
                    carrierId={this.props.carrierId}
                    opportunityId={this.props.opportunityId}
                    contactId={this.props.contactId}
                    handleOpenSignatureModal={this.handleOpenSignatureModal}
                    windowWidth={this.state.windowWidth}
                    setUploaderErrorMessage={this.setUploaderErrorMessage}
                  />
                </div>
                <div
                  style={
                    this.state.fullScreen
                      ? {
                          marginTop: "5px",
                          width: "auto",
                          maxWidth: "80vw",
                          height: "auto",
                          maxHeight: "30px",
                          overflow: "auto",
                          position: "absolute",
                          bottom: "8.5vh",
                        }
                      : {
                          marginTop: "5px",
                          width: "auto",
                          maxWidth: "53vw",
                          height: "auto",
                          maxHeight: "30px",
                          overflow: "auto",
                          position: "absolute",
                          bottom: "8.5vh",
                        }
                  }
                >
                  <ReactTooltip />
                  <input
                    style={{ display: "none" }}
                    id="file-upload"
                    type="file"
                    ref={this.fileInput}
                    onChange={this.handleInputChange}
                    onClick={this.onInputClick}
                  />
                  {this.state.fileNames?.map((file) => (
                    <span
                      style={{
                        background: "#C0EAD8",
                        width: "auto",
                        maxWidth: "180px",
                        fontSize: "14px",
                        marginRight: "10px",
                        padding: "2px",
                        borderRadius: "2px",
                      }}
                      data-tip={file.name}
                    >
                      {file.name.length > 20
                        ? file.name.substring(0, 20)
                        : file.name}{" "}
                      <button
                        type="button"
                        onClick={() => this.removeAttachment(file.url)}
                        style={{
                          background: "transparent",
                          border: "none",
                          outline: "none",
                          marginLeft: "5px",
                          cursor: "pointer",
                        }}
                      >
                        x
                      </button>
                    </span>
                  ))}
                  {this.state.attachmentLoader && (
                    <span
                      style={{
                        background: "#C0EAD8",
                        width: "auto",
                        maxWidth: "180px",
                        fontSize: "14px",
                        marginRight: "10px",
                        padding: "2px",
                        borderRadius: "2px",
                      }}
                    >
                      Loading ...
                    </span>
                  )}
                </div>
                <div className="modal__buttons" style={{ bottom: "2.5%" }}>
                  <div
                    style={
                      this.state.fullScreen
                        ? { position: "relative", width: "89vw" }
                        : { position: "relative", width: "55vw" }
                    }
                  >
                    <div
                      className="button-sm-secondary modal__addButton modal__addButton-email-sequence"
                      onClick={this.emailDecision}
                      style={{ padding: "6px 8px 2px" }}
                    >
                      Sequence
                    </div>
                    <div
                      className="button-sm-secondary modal__addButton modal__addButton-email-template"
                      style={{ padding: "4px 8px" }}
                    >
                      <DropdownTemplate
                        handleOpenTemplateModal={this.handleOpenTemplateModal}
                        handleOpenTemplateEditModal={
                          this.handleOpenTemplateEditModal
                        }
                        handleOpenTemplateDeleteModal={
                          this.handleOpenTemplateDeleteModal
                        }
                        allTemplates={this.state.allTemplates}
                        getAllTemplates={this.getAllTemplates}
                        readTemplateValues={this.readTemplateValues}
                        getSingleTemplateData={this.getSingleTemplateData}
                      />
                    </div>
                    <Hidden sm xs>
                      <div
                        style={{
                          position: "absolute",
                          bottom: "0px",
                          right: "0px",
                          height: "35px",
                          display: "flex",
                        }}
                      >
                        <button
                          type="submit"
                          disabled={this.state.isLoading}
                          className="button-md modal__confirm-button"
                          style={{
                            position: "static",
                            padding: "2px 17px",
                            width: "80.22px",
                            height: "35px",
                            borderRadius: "4px 0px 0px 4px",
                          }}
                        >
                          {this.state.isLoading ? "Sending" : "Send"}
                        </button>
                        <div className="modal__addButton-email-schedule">
                          <DropdownSchedule
                            scheduleDate={this.state.scheduleDate}
                            scheduleTime={this.state.scheduleTime}
                            dateChangeHandler={this.dateChangeHandler}
                            timeChangeHandler={this.timeChangeHandler}
                            scheduleErrorMessage={
                              this.state.scheduleErrorMessage
                            }
                            submitHandler={this.submitHandler}
                            isLoading={this.state.scheduleIsLoading}
                          />
                        </div>
                      </div>
                    </Hidden>
                    <Visible sm xs>
                      <div style={{ display: "flex" }}>
                        <button
                          type="submit"
                          className="button-md modal__confirm-button"
                          disabled={this.state.isLoading}
                          style={{
                            position: "static",
                            padding: "2px 17px",
                            width: "80.22px",
                            height: "35px",
                          }}
                        >
                          {this.state.isLoading ? "Sending" : "Send"}
                        </button>
                        <div className="modal__addButton-email-schedule">
                          <DropdownSchedule
                            scheduleDate={this.state.scheduleDate}
                            scheduleTime={this.state.scheduleTime}
                            dateChangeHandler={this.dateChangeHandler}
                            timeChangeHandler={this.timeChangeHandler}
                            scheduleErrorMessage={
                              this.state.scheduleErrorMessage
                            }
                            submitHandler={this.submitHandler}
                            isLoading={this.state.scheduleIsLoading}
                          />
                        </div>
                      </div>
                    </Visible>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default SendEmailModal;
