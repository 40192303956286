export const contactHeaderMapping = {
  contact_owner: "Contact Owner",
  name: "Contact Name",
  account_name: "Account Name",
  carrier_name: "Carrier Name",
  source: "Source",
  state: "State",
  zip: "Zip",
  city: "City",
  address: "Address",
  linkedin: "LinkedIn",
  country: "Country",
  email: "Email",
  formatted_phone: "Phone",
  phone_ext: "Phone Extension",
  formatted_mobile: "Mobile",
  description: "Description",
  status: "Status",
  preferred_communication: "Preferred Communication",
  opportunity_name: "Deal Name",
  role: "Job Title",
};
