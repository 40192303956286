import React from "react";
import "../../sass/DropdownHeader.scss";
import TaskEditModal from "./../TaskEditModal";
import ShowMore from "../../images/ShowMore.svg";
import { NotificationManager } from "react-notifications";
import axios from "axios";
import moment from "moment";
import ReusableModal from "../Reusables/ReusableModal";

class DropdownTask extends React.Component {
  state = {
    displayMenu: false,
    showDeleteModal: false,
    deleteLoader: false,
  };

  wrapperRef = React.createRef();
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside = (event) => {
    if (
      this.state.displayMenu &&
      this.wrapperRef &&
      !this.wrapperRef.current?.contains(event.target)
    ) {
      this.setState({ displayMenu: false });
    }
  };

  showDropdownMenu = (event) => {
    event.preventDefault();
    this.setState({ displayMenu: !this.state.displayMenu });
  };

  handleOpenModal = () => {
    this.props.setShowTaskModal(true);
    this.setState({ displayMenu: false });
  };

  handleCloseModal = () => {
    this.props.setShowTaskModal(false);
  };
  submitHandler = (dateType) => {
    const today = new Date();
    let date = "";
    if (dateType === "today") {
      date = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    } else if (dateType === "tomorrow") {
      date = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + 1
      );
    } else {
      date = this.props.date;
    }
    this.setState({ displayMenu: false });
    axios({
      method: "PUT",
      url: `/tasks/${this.props.task_id}`,
      data: {
        ...(this.props.account_id !== "" &&
          this.props.account_id !== null &&
          this.props.account_id !== undefined && {
            account_id: this.props.account_id,
          }),
        ...(this.props.contactId !== "" &&
          this.props.contactId !== null && {
            contact_id: this.props.contactId,
          }),
        ...(this.props.opportunityId !== "" &&
          this.props.opportunityId !== null && {
            opportunity_id: this.props.opportunityId,
          }),
        name: this.props.name,
        description: this.props.detail,
        date: date && moment(date).format("YYYY-MM-DD"),
        start_time:
          this.props.start_time !== null
            ? moment(this.props.start_time, "HH:mm:ss").format("HH:mm")
            : null,
        end_time:
          this.props.end_time !== null
            ? moment(this.props.end_time, "HH:mm:ss").format("HH:mm")
            : null,
        category_id: this.props.category_id,
        assigned_to: this.props.assignedTo,
      },
    })
      .then((response) => {
        NotificationManager.success(`Task assigned to ${dateType}.`);
        this.props.getTasks();
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        if (error?.response?.status === 406) {
          NotificationManager.error("Please assign task to a different user.");
        } else {
          NotificationManager.error("Error assigning task.");
        }
      });
  };

  deleteAllTasks = () => {
    this.setState({
      deleteLoader: true,
    });
    const recordId =
      this.props.typeOfTask === "account"
        ? this.props.account_id
        : this.props.typeOfTask === "contact"
        ? this.props.contactId
        : this.props.typeOfTask === "opportunity"
        ? this.props.opportunityId
        : this.props.carrier_id;
    axios({
      method: "DELETE",
      url: `${
        this.props.typeOfTask === "opportunity"
          ? "opportunitie"
          : this.props.typeOfTask
      }s/${recordId}/tasks`,
    })
      .then((res) => {
        this.setState({
          deleteLoader: false,
        });
        NotificationManager.success(`Tasks deleted successfully.`);
        this.props.getTasks();
      })
      .catch((err) => {
        this.setState({
          deleteLoader: false,
        });
        NotificationManager.error("Error deleting tasks.");
      });
  };

  render() {
    return (
      <div
        className="dropdownTask"
        ref={this.wrapperRef}
        style={{ background: "transparent", width: "20px" }}
      >
        <div
          className="button"
          style={{ fontSize: "14px" }}
          onClick={this.showDropdownMenu}
        >
          <img src={ShowMore} alt="V" width={14} />
        </div>

        {this.state.displayMenu ? (
          <ul style={{ width: "95px" }}>
            <li onClick={this.handleOpenModal} style={{ width: "75px" }}>
              <span
                className="active"
                style={{ border: "none", background: "transparent" }}
              >
                Edit Task
              </span>
            </li>
            <li
              onClick={() => this.submitHandler("today")}
              style={{ width: "75px" }}
            >
              <span
                className="active"
                style={{ border: "none", background: "transparent" }}
              >
                Today
              </span>
            </li>
            <li
              onClick={() => this.submitHandler("tomorrow")}
              style={{ width: "75px" }}
            >
              <span
                className="active"
                style={{ border: "none", background: "transparent" }}
              >
                Tomorrow
              </span>
            </li>
            <li
              onClick={() =>
                this.setState({
                  showDeleteModal: true,
                })
              }
              style={{ width: "75px" }}
            >
              <span
                className="active"
                style={{ border: "none", background: "transparent" }}
              >
                Delete All Tasks
              </span>
            </li>
          </ul>
        ) : null}
        <TaskEditModal
          accountId={this.props.account_id}
          carrierId={this.props.carrier_id}
          showTaskModal={this.props.showTaskModal}
          setShowTaskModal={this.handleOpenModal}
          handleCloseModal={this.handleCloseModal}
          name={this.props.name}
          time={this.props.time}
          detail={this.props.detail}
          taskId={this.props.taskId}
          start_time={this.props.start_time}
          end_time={this.props.end_time}
          task_id={this.props.task_id}
          date={this.props.date}
          accountName={this.props.accountName}
          carrierName={this.props.carrierName}
          contactName={this.props.contactName}
          opportunityName={this.props.opportunityName}
          category_id={this.props.category_id}
          opportunityId={this.props.opportunityId}
          contactId={this.props.contactId}
          getTasks={this.props.getTasks}
          assignedTo={this.props.assignedTo}
          users={this.props.users}
          opportunities={this.props.opportunities}
          contacts={this.props.contacts}
        />
        <ReusableModal
          showModal={this.state.showDeleteModal}
          handleCloseModal={() =>
            this.setState({
              showDeleteModal: false,
            })
          }
          submitHandler={this.deleteAllTasks}
          modalHeading={`Delete All Tasks`}
          modalPara={`Are you sure you want to delete all tasks?`}
          modalHeight={`30`}
          modalTop={`25`}
          loading={this.state.deleteLoader}
        />
      </div>
    );
  }
}

export default DropdownTask;
