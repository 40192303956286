import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import LinkIcon from "@mui/icons-material/Link";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import XIcon from "@mui/icons-material/X";
import ApolloSortingDropdown from "./ApolloSortingDropdown";
import { NotificationManager } from "react-notifications";
import TransferOwnershipModal from "../Reports/TransferOwnershipModal";
import ApolloImportSuccessModal from "./ApolloImportSuccessModal";
import TransferAccountOwnershipModal from "../Reports/TransferAccountOwnershipModal";

const ApolloLeadGenList = () => {
  const [type, setType] = useState("contacts");
  const [loading, setLoading] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [pushLoading, setPushLoading] = useState(false);
  const [contactList, setContactList] = useState([]);
  const [contactDetailList, setContactDetailList] = useState([]);
  const [labelId, setLabelId] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [total, setTotal] = useState(0);
  const [isAscending, setIsAscending] = useState("Desc");
  const [sortingParam, setSortingParam] = useState("[none]");
  const [allReportTransfer, setAllReportTransfer] = useState(false);
  const [excludeArray, setExcludeArray] = useState([]);
  const [transferAccount, setTransferAccount] = useState([]);
  const [showTranferModal, setShowTranferModal] = useState(false);
  const [showAccountTranferModal, setShowAccountTranferModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [ownerErrorMessage, setOwnerErrorMessage] = useState(false);

  const getRecordListNames = () => {
    setContactDetailList([]);
    setActivePage(1);
    setSortingParam("[none]");
    setAllReportTransfer(false);
    setExcludeArray([]);
    setTransferAccount([]);
    setLoading(true);
    axios({
      method: "GET",
      url: `/apollo/account-or-contact-list?type=${type}`,
    })
      .then((res) => {
        setLoading(false);
        if (res.data?.error) {
          NotificationManager.error(res.data?.error?.message);
        } else {
          setContactList(res.data.contact_list);
        }
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.message;
        if (error?.response?.status === 500) {
          NotificationManager.error("Error connecting to Apollo.");
        } else {
          NotificationManager.error(errorMessage);
        }
      });
  };
  const getRecordListDetails = (labelId, page = activePage) => {
    setListLoading(true);
    setAllReportTransfer(false);
    setExcludeArray([]);
    setTransferAccount([]);
    axios({
      method: "POST",
      url: `/apollo/${type === "accounts" ? "account" : "contact"}-list-search`,
      data: {
        ...(type === "contacts" && { contact_label_ids: [labelId] }),
        ...(type === "accounts" && { account_label_ids: [labelId] }),
        sort_by_field: sortingParam,
        sort_ascending: isAscending === "Asc" ? true : false,
        page,
      },
    })
      .then((res) => {
        setListLoading(false);
        if (res.data?.error) {
          NotificationManager.error(res.data?.error?.message);
        } else {
          setContactDetailList(res.data[type]);
          setTotal(res.data.pagination?.total_entries);
        }
      })
      .catch((error) => {
        setListLoading(false);
        const errorMessage = error?.response?.data?.message;
        if (error?.response?.status === 500) {
          NotificationManager.error("Error connecting to Apollo.");
        } else {
          NotificationManager.error(errorMessage);
        }
      });
  };

  const handleAllReportTransfer = () => {
    setAllReportTransfer(!allReportTransfer);
    setTransferAccount([]);
    setExcludeArray([]);
  };
  const transferAccountChangeHandler = (value, e) => {
    console.log("apollo check value", value);
    if (e.target.name === "transferAccount") {
      if (e.target.checked === true) {
        const newTransferAccount = [
          ...transferAccount.filter((com) => com !== ""),
          value,
        ];
        setTransferAccount(newTransferAccount);
      } else {
        const transferAccountFiltered = transferAccount.filter(
          (pc) => pc?.id?.toString() !== value?.id?.toString()
        );
        setTransferAccount(transferAccountFiltered);
      }
    }
  };
  const excludeAccountChangeHandler = (e) => {
    const { value } = e.target;
    if (e.target.checked === false) {
      const newExcludeArray = [
        ...excludeArray.filter((com) => com !== ""),
        value,
      ];
      setExcludeArray(newExcludeArray);
    } else {
      const excludeArrayFiltered = excludeArray.filter((pc) => pc !== value);
      setExcludeArray(excludeArrayFiltered);
    }
  };
  const pushToSalesdash = (primaryOwnerId, sharedAccountOwners) => {
    if (primaryOwnerId === null) {
      setOwnerErrorMessage(true);
      return false;
    }
    const ownerId = primaryOwnerId === "unassigned" ? null : primaryOwnerId;
    axios({
      method: "POST",
      url: `/apollo/import-records`,
      data: {
        record_type: type,
        ...(allReportTransfer &&
          type === "accounts" && {
            account_list_id: labelId,
            excluded_ids: excludeArray,
            owners: !ownerId
              ? []
              : sharedAccountOwners
                  ?.map((user) => ({
                    is_primary: false,
                    owner_id: parseInt(user.value),
                  }))
                  .concat([
                    {
                      is_primary: true,
                      owner_id: parseInt(ownerId),
                    },
                  ]),
          }),

        ...(allReportTransfer &&
          type === "contacts" && {
            contact_list_id: labelId,
            contact_owner_id: parseInt(ownerId),
            excluded_ids: excludeArray,
          }),
        ...(!allReportTransfer &&
          type === "accounts" && {
            accounts: transferAccount?.map((account) => ({
              name: account.name,
              website: account?.website_url || null,
              linkedin: account?.linkedin_url || null,
              formatted_phone: account?.sanitized_phone || null,
              city: account?.organization_city,
              state: account?.organization_state,
              zip: account?.organization_postal_code,
              address: account?.organization_raw_address,
              owners: !ownerId
                ? []
                : sharedAccountOwners
                    ?.map((user) => ({
                      is_primary: false,
                      owner_id: parseInt(user.value),
                    }))
                    .concat([
                      {
                        is_primary: true,
                        owner_id: parseInt(ownerId),
                      },
                    ]),
              source: "apollo",
            })),
          }),
        ...(!allReportTransfer &&
          type === "contacts" && {
            contacts: transferAccount?.map((contact) => ({
              name: contact?.name,
              linkedin: contact?.linkedin_url || null,
              account_name: contact?.account?.name || null,
              account_website: contact?.account?.website_url || null,
              account_linkedin: contact?.account?.linkedin_url || null,
              account_formatted_phone:
                contact?.account?.primary_phone?.number ||
                contact?.account?.sanitized_phone ||
                null,
              role: contact?.title || null,
              city: contact?.city || null,
              state: contact?.state || null,
              country: contact?.country || null,
              formatted_phone: contact?.sanitized_phone || null,
              email: contact?.email || null,
              contact_owner_id: parseInt(ownerId),
              source: "apollo",
            })),
          }),
      },
    })
      .then((res) => {
        setShowTranferModal(false);
        setShowAccountTranferModal(false);
        setAllReportTransfer(false);
        setTransferAccount([]);
        setShowSuccessModal(true);
        setExcludeArray([]);
        setOwnerErrorMessage(false);
        NotificationManager.success(res.data?.message);
      })
      .catch((error) => {
        if (error?.response?.status === 500) {
          NotificationManager.error("Error importing records.");
        } else {
          NotificationManager.error(error?.response?.data.message);
        }
      });
  };

  useEffect(() => {
    getRecordListNames();
  }, [type]);
  console.log(
    "apollo check",
    allReportTransfer,
    "transfer",
    transferAccount,
    "exclude",
    excludeArray
  );
  return (
    <div className="accountList">
      <TransferOwnershipModal
        showOpportunityModal={showTranferModal}
        setShowOpportunityModal={() => setShowTranferModal(true)}
        handleCloseModal={() => setShowTranferModal(false)}
        transferOwnership={pushToSalesdash}
        ownerErrorMessage={ownerErrorMessage}
        fromAccountsReport={true}
      />
      <TransferAccountOwnershipModal
        showOpportunityModal={showAccountTranferModal}
        setShowOpportunityModal={() => setShowAccountTranferModal(true)}
        handleCloseModal={() => setShowAccountTranferModal(false)}
        transferOwnership={pushToSalesdash}
        ownerErrorMessage={ownerErrorMessage}
        fromAccountsReport={true}
      />
      <ApolloImportSuccessModal
        showOpportunityModal={showSuccessModal}
        setShowOpportunityModal={() => setShowSuccessModal(true)}
        handleCloseModal={() => setShowSuccessModal(false)}
      />
      <div
        style={{
          borderBottom: "1px solid #E3E3E3",
          marginBottom: "10px",
        }}
      >
        <div style={{ display: "inline-block" }}>
          <h2 style={{ marginBottom: "10px" }}>Apollo Lead Generation</h2>
        </div>
      </div>
      <div className="accountList__function"></div>
      <div style={{ marginBottom: "20px", marginTop: "20px" }}>
        <span
          onClick={() => setType("contacts")}
          style={
            type === "contacts"
              ? {
                  textDecoration: "none",
                  color: "#4BC893",
                  padding: "4px 12px",
                  borderBottom: "1px solid #4BC893",
                  fontWeight: "500",
                  cursor: "pointer",
                }
              : {
                  textDecoration: "none",
                  color: "black",
                  padding: "4px 12px",
                  cursor: "pointer",
                }
          }
        >
          Contacts
        </span>
        <span
          onClick={() => setType("accounts")}
          style={
            type === "accounts"
              ? {
                  textDecoration: "none",
                  color: "#4BC893",
                  padding: "4px 12px",
                  borderBottom: "1px solid #4BC893",
                  fontWeight: "500",
                  cursor: "pointer",
                }
              : {
                  textDecoration: "none",
                  color: "black",
                  padding: "4px 12px",
                  cursor: "pointer",
                }
          }
        >
          Accounts
        </span>
      </div>
      {loading ? (
        <div class="load-wrapp">
          <div class="load-3">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </div>
        </div>
      ) : (
        <div style={{ display: "flex" }}>
          <div>
            <table className="accountList__table">
              <thead>
                <tr>
                  <td>List Name</td>
                  {!contactDetailList?.length ? <td>Last Modified</td> : null}
                  {!contactDetailList?.length ? <td>Records</td> : null}
                </tr>
              </thead>
              <tbody>
                {contactList?.map((list) => {
                  return (
                    <tr key={list.id}>
                      <td
                        onClick={() => {
                          setLabelId(list.id);
                          setActivePage(1);
                          getRecordListDetails(list.id, 1);
                        }}
                        style={{ cursor: "pointer", color: "#41BE89" }}
                      >
                        {list?.name}
                      </td>
                      {!contactDetailList?.length ? (
                        <td>{moment(list?.updated_at).format("l")}</td>
                      ) : null}
                      {!contactDetailList?.length ? (
                        <td>{list?.cached_count}</td>
                      ) : null}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div style={{ marginLeft: "20px" }}>
            {contactDetailList?.length ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  height: "0",
                  transform: "translateY(-45px)",
                }}
              >
                <ApolloSortingDropdown
                  isLoading={listLoading}
                  type={type}
                  submitHandler={getRecordListDetails}
                  sortingParam={sortingParam}
                  setSortingParam={setSortingParam}
                  labelId={labelId}
                  setIsAscending={setIsAscending}
                  isAscending={isAscending}
                />
                <button
                  type="button"
                  onClick={() =>
                    type === "accounts"
                      ? setShowAccountTranferModal(true)
                      : setShowTranferModal(true)
                  }
                  className="button-md"
                  disabled={
                    total === 0 ||
                    (allReportTransfer === false &&
                      transferAccount.length === 0)
                  }
                  style={
                    total === 0 ||
                    (allReportTransfer === false &&
                      transferAccount.length === 0)
                      ? {
                          marginLeft: "15px",
                          background: "#e3e3e3",
                          cursor: "not-allowed",
                        }
                      : { marginLeft: "15px" }
                  }
                >
                  Import to Salesdash
                </button>
              </div>
            ) : null}
            {listLoading ? (
              <div class="load-wrapp">
                <div class="load-3">
                  <div class="line"></div>
                  <div class="line"></div>
                  <div class="line"></div>
                </div>
              </div>
            ) : contactDetailList?.length ? (
              <table className="accountList__tableReport">
                <thead>
                  <tr>
                    <th style={{ width: "8px" }}>
                      <input
                        type="checkbox"
                        style={{ width: "12px", height: "12px" }}
                        onClick={handleAllReportTransfer}
                      />
                    </th>
                    {type === "contacts" && <th>Name</th>}
                    {type === "contacts" && <th>Title</th>}
                    <th style={{ width: "255px" }}>Company</th>
                    <th>Location</th>
                    <th>Phone</th>
                  </tr>
                </thead>
                <tbody>
                  {contactDetailList?.map((list) => {
                    return (
                      <tr key={list.id}>
                        <td style={{ width: "8px" }}>
                          {allReportTransfer ? (
                            <input
                              type="checkbox"
                              style={{ width: "12px", height: "12px" }}
                              name="transferAccount"
                              value={list?.id}
                              checked={
                                !excludeArray.includes(list?.id?.toString())
                              }
                              onClick={excludeAccountChangeHandler}
                            />
                          ) : (
                            <input
                              type="checkbox"
                              style={{ width: "12px", height: "12px" }}
                              name="transferAccount"
                              value={list}
                              onClick={(e) =>
                                transferAccountChangeHandler(list, e)
                              }
                            />
                          )}
                        </td>
                        {type === "contacts" && <td>{list?.name}</td>}
                        {type === "contacts" && <td>{list?.title}</td>}
                        <td>
                          <div style={{ display: "flex" }}>
                            <img
                              src={
                                type === "contacts"
                                  ? list?.account?.logo_url
                                  : list?.logo_url
                              }
                              width="35px"
                              height="35px"
                              alt=""
                            />{" "}
                            <div style={{ marginLeft: "10px" }}>
                              {type === "contacts"
                                ? list?.account?.name
                                : list?.name}
                              {type === "contacts" ? (
                                <div style={{ display: "flex" }}>
                                  {list?.account?.website_url && (
                                    <a
                                      href={list?.account?.website_url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <LinkIcon
                                        sx={{
                                          fontSize: 18,
                                          color: "#41be89",
                                          transform: "translateY(4px)",
                                          marginRight: "5px",
                                        }}
                                      />
                                    </a>
                                  )}
                                  {list?.account?.linkedin_url && (
                                    <a
                                      href={list?.account?.linkedin_url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <LinkedInIcon
                                        sx={{
                                          fontSize: 18,
                                          color: "#1876d1",
                                          transform: "translateY(4px)",
                                          marginRight: "5px",
                                        }}
                                      />
                                    </a>
                                  )}
                                  {list?.account?.facebook_url && (
                                    <a
                                      href={list?.account?.facebook_url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <FacebookIcon
                                        sx={{
                                          fontSize: 18,
                                          color: "#1876d1",
                                          transform: "translateY(4px)",
                                          marginRight: "5px",
                                        }}
                                      />
                                    </a>
                                  )}
                                  {list?.account?.twitter_url && (
                                    <a
                                      href={list?.account?.twitter_url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <XIcon
                                        sx={{
                                          fontSize: 18,
                                          color: "#212121",
                                          transform: "translateY(4px)",
                                          marginRight: "5px",
                                        }}
                                      />
                                    </a>
                                  )}
                                </div>
                              ) : (
                                <div style={{ display: "flex" }}>
                                  {list?.website_url && (
                                    <a
                                      href={list?.website_url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <LinkIcon
                                        sx={{
                                          fontSize: 18,
                                          color: "#41be89",
                                          transform: "translateY(4px)",
                                          marginRight: "5px",
                                        }}
                                      />
                                    </a>
                                  )}
                                  {list?.linkedin_url && (
                                    <a
                                      href={list?.linkedin_url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <LinkedInIcon
                                        sx={{
                                          fontSize: 18,
                                          color: "#1876d1",
                                          transform: "translateY(4px)",
                                          marginRight: "5px",
                                        }}
                                      />
                                    </a>
                                  )}
                                  {list?.facebook_url && (
                                    <a
                                      href={list?.facebook_url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <FacebookIcon
                                        sx={{
                                          fontSize: 18,
                                          color: "#1876d1",
                                          transform: "translateY(4px)",
                                          marginRight: "5px",
                                        }}
                                      />
                                    </a>
                                  )}
                                  {list?.twitter_url && (
                                    <a
                                      href={list?.twitter_url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <XIcon
                                        sx={{
                                          fontSize: 18,
                                          color: "#212121",
                                          transform: "translateY(4px)",
                                          marginRight: "5px",
                                        }}
                                      />
                                    </a>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </td>
                        <td>
                          {type === "contacts"
                            ? `${list?.city}, ${list?.state}`
                            : `${list?.organization_city}, ${list?.organization_state}`}
                        </td>
                        <td>
                          {type === "contacts" ? (
                            list?.phone_numbers?.length > 0 ? (
                              list?.phone_numbers?.map((num) => (
                                <div>
                                  <a
                                    target="_blank"
                                    href={`tel:${num.sanitized_number}`}
                                    data-stringify-link={`tel:${num.sanitized_number}`}
                                    delay="150"
                                    rel="noopener noreferrer"
                                    tabindex="-1"
                                    data-remove-tab-index="true"
                                  >
                                    {num.raw_number}
                                  </a>
                                </div>
                              ))
                            ) : (
                              <a
                                target="_blank"
                                href={`tel:${list?.sanitized_phone}`}
                                data-stringify-link={`tel:${list?.sanitized_phone}`}
                                delay="150"
                                rel="noopener noreferrer"
                                tabindex="-1"
                                data-remove-tab-index="true"
                              >
                                {list?.sanitized_phone}
                              </a>
                            )
                          ) : (
                            <a
                              target="_blank"
                              href={`tel:${list?.sanitized_phone}`}
                              data-stringify-link={`tel:${list?.sanitized_phone}`}
                              delay="150"
                              rel="noopener noreferrer"
                              tabindex="-1"
                              data-remove-tab-index="true"
                            >
                              {list?.phone ?? list?.sanitized_phone}
                            </a>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : null}
            {contactDetailList?.length ? (
              <Pagination
                activePage={activePage}
                itemsCountPerPage={25}
                totalItemsCount={total}
                pageRangeDisplayed={5}
                onChange={(page) => {
                  setActivePage(page);
                  getRecordListDetails(labelId, page);
                }}
                itemClass="page-item"
                linkClass="page-link"
                hideDisabled
              />
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default ApolloLeadGenList;
